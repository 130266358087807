import React, { useState } from "react";
import CustomDropdown from "../../Pages/Shared/CustomDropdown/CustomDropdown";

const WhatsAppFormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  const options = [
    {
      title: "Live Classess",
      value: "live_classess",
    },
    {
      title: "Test Series",
      value: "test_series",
    },
    {
      title: "New Course Announcement",
      value: "new_course_annouce",
    },
    {
      title: "New Topic Announcement",
      value: "new_topic_annouce",
    },
  ];

  return (
    <div className="">
      <CustomDropdown options={options} type="type" />
    </div>
  );
};

export default WhatsAppFormComponent;

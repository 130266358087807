import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBook, FaRegBell } from "react-icons/fa6";
import { MdDashboard, MdOutlineSdStorage } from "react-icons/md";
import { BsPatchQuestionFill } from "react-icons/bs";
import { SiYoutubeshorts } from "react-icons/si";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { AuthContext } from "../../../Providers/AuthProvider";
import logo from "../../../assets/logo.avif";

function MenuSidebar() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const location = useLocation();

  // Example doubt count
  const [doubtCount, setDoubtCount] = useState(4); // Adjust the number as needed

  const dashboardMenu = [
    {
      title: "Dashboard",
      link: "/",
      icon: <MdDashboard />,
    },
    {
      title: "Courses",
      link: "/add-course",
      icon: <FaBook />,
    },
    {
      title: "Cloud Storage",
      link: "/cloud-storage",
      icon: <MdOutlineSdStorage />,
    },
    {
      title: "Notification",
      link: "/notification",
      icon: <FaRegBell />,
    },
    {
      title: "Shorts",
      link: "/shorts",
      icon: <SiYoutubeshorts />,
    },
    {
      title: "Doubts",
      link: "/doubts",
      icon: <BsPatchQuestionFill />,
      hasCount: true, // Flag to identify if count should be shown
    },
  ];

  return (
    <div className="w-[16rem] min-h-full tablet:w-[20%] bg-primaryColor py-8 lg:relative h-[100vh] overflow-y-auto no-scrollbar shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] ">
      <div className="flex justify-center items-center px-10 tablet:px-1">
        <img src={logo} alt="" className="w-[15rem] ms-3 invert" />
      </div>

      <ul className="flex flex-col text-left text-white mt-10">
        {dashboardMenu.map((item, index) => (
          <li
            className={`flex w-full space-between mt-1 text-base ${
              location.pathname === item?.link
                ? "border-l-4 bg-[#313342]"
                : "border-l-4 border-transparent"
            }`}
            key={index}
          >
            <p
              onClick={() => navigate(item.link)}
              className={`flex items-center h-full w-full px-6 py-3 hover:cursor-pointer hover:bg-purpleColor  tablet:px-1 tablet:text-sm`}
            >
              <span className="me-3 text-xl">{item.icon}</span>
              {item.title}

              {/* Display doubt count if applicable */}
              {item.hasCount && doubtCount > 0 && (
                <span className="ml-auto bg-red-500 text-white text-xs rounded-full px-2 py-1">
                  {doubtCount}
                </span>
              )}
            </p>
          </li>
        ))}
      </ul>

      <div className="flex justify-center mt-10">
        <CustomButton
          lable={`Logout`}
          className={`bg-white border-none !rounded-full py-3 !px-10 !font-normal !mx-0`}
          onClick={() => logout()}
        />
      </div>

      <Link
        to="https://www.phynlabz.com"
        className="text-white font-normal text-xs bottom-10 w-full text-center absolute"
      >
        Powered by &copy;PhynLabz Tech
        <br />
        <span className="mt-2 text-xsm">Version 1.0.0 alpha</span>
      </Link>
    </div>
  );
}

export default MenuSidebar;

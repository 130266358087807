import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [userData, setUserData] = useState(null);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      return Date.now() / 1000 > decoded.exp;
    } catch (error) {
      console.error("Invalid token detected:", error.message);
      logout(); // Log out the user if the token is invalid
      return true;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");

    if (token) {
      if (!isTokenExpired(token)) {
        setAccessToken(token);
        if (userData) {
          setUserData(JSON.parse(userData));
        }
      } else {
        logout(); // Logout if the token is expired
      }
    }
  }, []);

  const login = (token, data) => {
    setAccessToken(token);
    setUserData(data);
    localStorage.setItem("token", token);
    localStorage.setItem("userData", JSON.stringify(data));
    window.location.href = "/"; // Redirect after login
  };

  const logout = () => {
    setAccessToken(null);
    setUserData(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ login, logout, accessToken, userData }}>
      {children}
    </AuthContext.Provider>
  );
};

import React from "react";

function CustomButton({
  onClick,
  lable,
  className,
  children,
  hasIcon = false,
  disabled
}) {
  return hasIcon ? (
    <button
      className={` text-xs md:text-sm px-3 md:px-5 py-2  border border-black  rounded-full mx-3  shadow-2xl ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex flex-col lg:flex-row items-center justify-center shadow-no-border-shadow-2 ">
        <p className="flex flex-col lg:flex-row  items-center justify-center">
          {children}

          <span className="mx-2 text-[9px] hidden lg:block lg:text-xs">
            {lable}
          </span>
        </p>
      </div>
    </button>
  ) : (
    <button
      className={` text-xs md:text-sm px-3 md:px-5 py-2 border border-black  rounded-lg mx-3  ${className}`}
      onClick={onClick}
    >
      {lable}
    </button>
  );
}

export default CustomButton;

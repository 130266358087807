import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../Api/ApiConstant";

export const AdminApi = createApi({
  reducerPath: "admin",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiConstant.BASE_URL,
  }),
  endpoints: (builder) => ({
    getAllCourse: builder.query({
      query: (id) => `${ApiConstant.GET_ALL_COURSE}`,
    }),
    getAllCoursesByInstrutorId: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstant.GET_COURSE_BY_INSTRUCTOR_ID}/${id}`,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    getAllCoursesByGoalId: builder.query({
      query: (goalId) => `${ApiConstant.GET_ALL_COURSE_BY_GOAL_ID}/${goalId}`,
    }),
    getCoursesByCourseId: builder.query({
      query: (id) => `${ApiConstant.GET_COURSE_BY_COURSE_ID}/${id}`,
    }),
    getAllLectureByChapterId: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstant.GET_ALL_LECTURE_BY_CHAPTER_ID}/${id}`,
        headers: {
          "auth-token": token,
        },
      }),
    }),

    getAllSections: builder.query({
      query: (id) => `${ApiConstant.GET_ALL_SECTION}/${id}`,
    }),

    getAllChaptersBySectionId: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstant.GET_CHAPTER_BY_SECTION_ID}/${id}`,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    getCourseByCloudVideos: builder.query({
      query: () => `${ApiConstant.CLOUD_VIDEOS}`,
    }),
    getAllGoal: builder.query({
      query: () => `${ApiConstant.GET_ALL_GOAL_SUMMARY}`,
    }),
    getLectureByLectureId: builder.query({
      query: (lectureId) =>
        `${ApiConstant.GET_LECTURE_VIDEO_BY_LECTURE_ID}/${lectureId}`,
    }),
    getQuestionsByLectureId: builder.query({
      query: (lectureId) =>
        `${ApiConstant.GET_QUESTIONS_BY_LECTURE_ID}/${lectureId}`,
    }),
    getAllEnrolledStudent: builder.query({
      query: (educatorId) =>
        `${ApiConstant.GET_ALL_ENROLLED_STUDENT}/${educatorId}`,
    }),
    getUplodedLiveClassess: builder.query({
      query: (educatorId) =>
        `${ApiConstant.GET_UPLODED_LIVE_CLASSESS}/${educatorId}`,
    }),
    getDirectOrder: builder.query({
      query: () => `${ApiConstant.GET_DIRECT_ORDER}`,
    }),

    educatorProfileInformation: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.EDUCATOR_PROFILE_REGISTRATION}`,
        method: "POST",
        body: body,
      }),
    }),
    educatorQualification: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.EDUCATOR_QUALIFICATION}`,
        method: "POST",
        body: body,
      }),
    }),
    educatorBankDetails: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.EDUCATOR_BANK_DETAILS}`,
        method: "POST",
        body: body,
      }),
    }),
    educatorKycDetails: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.EDUCATOR_KYC_DETAILS}`,
        method: "POST",
        body: body,
      }),
    }),
    createChapter: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstant.CREATE_CHAPTER}`,
        method: "POST",
        body: body,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    createCourse: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.CREATE_COURSE}`,
        method: "POST",
        body: body,
      }),
    }),
    createSection: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.CREATE_SECTION}`,
        method: "PUT",
        body: body,
      }),
    }),

    createNewLecture: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.ADD_LECTURE_TO_COURSE}`,
        method: "POST",
        body: body,
      }),
    }),
    uploadCourseThumbnail: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.UPLOAD_COURSE_THUMBNAIL}`,
        method: "POST",
        body: body,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      }),
    }),
    uploadLectureVideo: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.UPLOAD_LECTURE_VIDEO}`,
        method: "POST",
        body: body,
      }),
    }),
    educatorLogin: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.EDUCATOR_LOGIN}`,
        method: "POST",
        body: body,
      }),
    }),
    getEnrolledStudentByCourseId: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.GET_ENROLLED_STUDENT_BY_COURSE_ID}`,
        method: "POST",
        body: body,
      }),
    }),
    getEnrolledStudentByDate: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.GET_ENROLLED_STUDENT_BY_DATE}`,
        method: "POST",
        body: body,
      }),
    }),

    createQuestion: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.CREATE_QUESTION}`,
        method: "POST",
        body: body,
      }),
    }),
    sendNotificationByCourseId: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstant.SEND_NOTIFICATION_BY_COURSE_ID}`,
        method: "POST",
        body: body,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    publishCourse: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.PUBLISH_COURSE}`,
        method: "POST",
        body: body,
      }),
    }),
    publishChapter: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstant.PUBLISH_CHAPTER}`,
        method: "POST",
        body: body,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    addNotesToCourse: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.ADD_NOTES_TO_COURSE}`,
        method: "PUT",
        body: body,
      }),
    }),
    markLectureAsTrail: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.MARKED_LECTURE_AS_TRAIL}`,
        method: "POST",
        body: body,
      }),
    }),
    markLectureAsIntro: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstant.MARKED_LECTURE_AS_INTRO}`,
        method: "POST",
        body: body,
      }),
    }),
    directAssignCourse: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstant.DIRECT_ASSIGN_COURSE}`,
        method: "POST",
        body: body,
        headers: {
          "auth-token": token,
        },
      }),
    }),
  }),
});

export const {
  useGetAllCoursesByGoalIdQuery,
  useGetAllCourseQuery,
  useGetCoursesByCourseIdQuery,
  useGetAllLectureByChapterIdQuery,
  useEducatorProfileInformationMutation,
  useEducatorQualificationMutation,
  useEducatorBankDetailsMutation,
  useEducatorKycDetailsMutation,
  useCreateChapterMutation,
  useGetAllCoursesByInstrutorIdQuery,
  useGetAllSectionsQuery,
  useGetAllChaptersBySectionIdQuery,
  useGetCourseByCloudVideosQuery,
  useCreateCourseMutation,
  useCreateSectionMutation,
  useGetAllGoalQuery,
  useUploadCourseThumbnailMutation,
  useCreateNewLectureMutation,
  useUploadLectureVideoMutation,
  useGetLectureByLectureIdQuery,
  useEducatorLoginMutation,
  useGetAllEnrolledStudentQuery,
  useGetEnrolledStudentByCourseIdMutation,
  useGetEnrolledStudentByDateMutation,
  useCreateQuestionMutation,
  useGetQuestionsByLectureIdQuery,
  useGetUplodedLiveClassessQuery,
  useSendNotificationByCourseIdMutation,
  usePublishCourseMutation,
  usePublishChapterMutation,
  useMarkLectureAsIntroMutation,
  useMarkLectureAsTrailMutation,
  useDirectAssignCourseMutation,
  useGetDirectOrderQuery,
} = AdminApi;

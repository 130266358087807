import React, { useState } from "react";
import folder_icon from "../../../../assets/folder_icon2.svg";

function LiveSessionFolder({ onDoubleClick }) {
	const [showFolderName, setShowFolderName] = useState(false);

	return (
		<div className="flex flex-col" onClick={onDoubleClick}>
			<div
				className="flex flex-col items-center justify-start cursor-pointer w-[5rem]  mx-3 rounded-lg  relative  h-[7.5rem]"
				onMouseEnter={() => setShowFolderName(true)}
				onMouseLeave={() => setShowFolderName(false)}
			>
				<div className="">
					<img src={folder_icon} alt="" className="" />
				</div>
				<div
					className={`px-2 w-full text-center text-xs  mb-2 -translate-y-1 absolute top-20 ${
						showFolderName ? "line-clamp-none bg-white" : "line-clamp-2 "
					}`}
				>
					Live Session
				</div>
			</div>
		</div>
	);
}

export default LiveSessionFolder;

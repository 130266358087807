import { useState } from "react";
import CustomInput from "../../../Pages/Shared/CustomInput/CustomInput";
import CustomTextarea from "../../../Pages/Shared/CustomTextarea/CustomTextarea";
import { useCreateNewLectureMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

const AddLectureForm = ({
  lectureAllData,
  chapterId,
  showAddLecturePopup,
  refetchAllLectureByChapter,
  setLectureAllData,
}) => {
  const [lectureTitle, setLectureTitle] = useState("");
  const [lectureDescription, setLectureDescription] = useState("");
  const [createNewLecture] = useCreateNewLectureMutation();

  const handleFormSave = async () => {
    if (
      lectureTitle === null ||
      lectureTitle === undefined ||
      lectureTitle.length === 0
    ) {
      enqueueSnackbar("Lecture Title is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (
      lectureDescription === null ||
      lectureDescription === undefined ||
      lectureDescription.length === 0
    ) {
      enqueueSnackbar("Lecture Description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }

    try {
      const response = await createNewLecture({
        body: {
          lectureName: lectureTitle,
          lectureDescription: lectureDescription,
          chapterId: chapterId,
        },
      });

      console.log("Lecture created", response);

      if (response) {
        showAddLecturePopup(false);
        refetchAllLectureByChapter();
      } else {
        console.log("Oops! Lecture is not created");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const handleCancle = () => {
    showAddLecturePopup(false);
    setLectureAllData({
      lectureName: "",
      lectureDescription: "",
      lectureUid: "",
    });
  };

  return (
    <div className="flex fixed h-screen w-full justify-center items-center top-0 bottom-0 left-0 right-0 overflow-y-hidden z-50">
      <div className="absolute w-screen h-screen bg-gray-900 opacity-40"></div>
      <div className="absolute bg-white w-full lg:w-[700px]  z-10 lg:rounded-2xl p-10 ">
        <p className="text-lg font-semibold text-[#313639] mb-12">
          {lectureAllData?.lectureName === ""
            ? " Create New Lecture"
            : " Update Lecture"}
        </p>

        <CustomInput
          value={lectureAllData?.lectureName || lectureTitle}
          inputLable={`Lecture Title `}
          onChange={(e) => {
            setLectureTitle(e.target.value);
            console.log("wsdad");
          }}
        />
        <CustomTextarea
          value={lectureAllData?.lectureDescription || lectureDescription}
          inputLable={`Lecture Description`}
          onChange={(e) => setLectureDescription(e.target.value)}
        />

        <button
          className="bg-red-500 text-white rounded-md px-4 py-2 mt-4 mx-4"
          onClick={() => {
            handleCancle();
          }}
        >
          Cancel
        </button>
        <button
          className="bg-green-500 text-white rounded-md px-4 py-2 mt-4"
          onClick={handleFormSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddLectureForm;

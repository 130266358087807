import React from "react";
import { CiStar } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";

function DashboardCourseTable() {
  const data = [
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 4.8,
      join: 120,
      date: "25 Dec 2021",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 4.8,
      join: 120,
      date: "25 Dec 2021",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 4.8,
      join: 120,
      date: "25 Dec 2021",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 4.8,
      join: 120,
      date: "25 Dec 2021",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 5.6,
      join: 120,
      date: "25 Dec 2021",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      rating: 9.4,
      join: 120,
      date: "25 Dec 2021",
    },
  ];
  return (
    <div className=" rounded-xl shadow-no-border-shadow-2 h-full flex flex-col justify-between">
      <div className="flex justify-between items-center px-5 pt-5">
        <p className="">My Latest Course</p>
        <p
          className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5 cursor-pointer`}
        >
          View All
        </p>
      </div>
      <div className="mt-4 bg-red-100 h-[16rem] overflow-y-scroll">
        <table className="w-full h-auto text-xsm lg:text-sm bg-white rounded">
          <thead>
            <tr className="border-b text-gray-400">
              <th className=" p-3 text-start">Courses </th>
              <th className=" p-3 text-center">Join Courses</th>
              <th className=" p-3 text-center">Rating</th>
              <th className=" p-3 text-end">Update Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((items, index) => (
              <tr
                key={index}
                className={` text-gray-500 cursor-pointer hover:bg-gray-50 `}
                style={{ fontFamily: "ArialHelvetica, sans-serif" }}
              >
                <td className=" p-3 text-start   font-medium w-[60%]">
                  <div className="flex items-center gap-3 ">
                    <img src={`${items?.image}`} alt="" className="w-[5rem]" />
                    <p className="line-clamp-2">{items?.courseName}</p>{" "}
                  </div>
                </td>
                <td className=" p-3 text-center  font-medium">
                  <div className="flex items-center justify-center gap-2">
                    <FaRegUserCircle />
                    {items?.join}
                  </div>
                </td>
                <td className=" p-3  text-center  ">
                  <div className="flex items-center justify-center gap-2">
                    <IoMdStar className="text-orange-600 text-xl" />
                    {items?.rating}
                  </div>
                </td>
                <td className=" p-3  text-end  ">{items?.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardCourseTable;

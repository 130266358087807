import React from "react";

import phone_hand from "../../assets/phone_with_hand.png";
import mobile_image from '../../assets/notification_mobile.png'
function PreviewNotificaton() {
	return (
    <div className="flex justify-center items-start shadow-lg mb-5 rounded-2xl p-10 ">
      <div className="w-[50%] pe-10 hidden lg:block">
        <img src={phone_hand} alt="" className="w-[4rem] mb-10" />
        <p className=" text-xl  font-semibold text-[#313639] mb-10">
          Personalize Notification
        </p>
        <p className="text-sm text-gray-500 mb-20 w-[80%]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          recusandae laborum excepturi eaque quisquam similique nostrum quod
          doloribus qui totam eos, soluta minus nam voluptatum magni cupiditate
          reiciendis? Rem, mollitia.
        </p>
        <div className="bg-green-500 h-[3px] w-[5rem] "></div>
      </div>
      <div className="w-full lg:w-[50%]  flex justify-center">
        <img src={mobile_image} alt="" className=" h-[25rem]" />
      </div>
    </div>
  );
}

export default PreviewNotificaton;

import React, { useContext, useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { Step, Stepper } from "react-form-stepper";
import { FiPlus } from "react-icons/fi";
import { useGetAllCoursesByInstrutorIdQuery } from "../../../redux/ReduxToolkit";
import ManageSection from "../AddSection/ManageSection";
import ManageChapter from "../AddChapter/ManageChapter";
import ManageQuestion from "../AddQuestion/ManageQuestion";
import CreateCoursePopup from "./CreateCoursePopup";
import CourseCard from "./CourseCrad";
import ManageLecture from "../AddLecture/ManageLecture";
import no_data_image from "../../../assets/no-course.png";
import CustomLoader from "../../Loading/Loading";
import { AuthContext } from "../../../Providers/AuthProvider";

function Course() {
  const [activeStep, setActiveStep] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [changeValueStepper, setChangeValueStepper] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [currentChapterId, setCurrentChapterId] = useState("");
  const [currentChapterIndex, setCurrentChapterIndex] = useState(null);
  const [newCourseId, setNewCourseId] = useState(null);
  const [currentLectureId, setCurrentLectureId] = useState(null);

  const { userData, accessToken } = useContext(AuthContext);

console.log(userData?.userInfo?._id);

  const {
    data: myCourse,
    refetch: myCourseRefetch,
    isLoading,
  } = useGetAllCoursesByInstrutorIdQuery({
    id: userData?.userInfo?._id,
    token: accessToken,
  });

  return (
    <div className="relative ">
      <div className="hidden lg:block absolute top-0 left-0 right-0 mx-auto z-50 bg-white">
        <div className="flex flex-col justify-center items-center rounded-md mb-10">
          <Stepper
            connectorStateColors={true}
            activeStep={activeStep}
            className="w-full text-sm"
            styleConfig={{
              completedBgColor: "#33B249",
              activeBgColor: "#008AD8",
            }}
            connectorStyleConfig={{
              activeColor: "#008AD8",
              completedColor: "#33B249",
            }}
          >
            <Step
              onClick={() => {
                setActiveStep(null);
                setChangeValueStepper(false);
                setCourseId(null);
              }}
              label={`${
                changeValueStepper ? " Create Course " : " Manage Course "
              }`}
            />
            <Step
              onClick={() => setActiveStep(1)}
              label={`${
                changeValueStepper ? "Create Section " : " Manage Section "
              }`}
            />
            <Step
              onClick={() => setActiveStep(2)}
              label={`${
                changeValueStepper ? "Create Chapter " : " Manage Chapter "
              }`}
            />
            <Step
              onClick={() => setActiveStep(3)}
              label={`${
                changeValueStepper ? "Add Lectures " : " Manage Lecture "
              }`}
            />
            <Step
              onClick={() => setActiveStep(4)}
              label={`${
                changeValueStepper ? "Create Question " : "Manage Question "
              }`}
            />
          </Stepper>
        </div>
      </div>
      {activeStep === null && (
        <CustomButton
          lable={`Add Course`}
          hasIcon={true}
          className={`w-[60px] h-[60px] lg:h-[50px] lg:w-[12rem] fixed bottom-3 right-5 lg:!mx-0 bg-purpleColor text-white border-none font-normal my-5 rounded-full z-50`}
          onClick={() => {
            setActiveStep(0);
            setChangeValueStepper(true);
            setCourseId(null);
          }}
        >
          <FiPlus className="text-3xl lg:text-xl" />
        </CustomButton>
      )}
      <div className="flex flex-col h-full">
        {isLoading && <CustomLoader />}

        <div className="flex justify-center w-full ">
          {activeStep === null && (
            <div className="flex justify-center w-full">
              <div className="flex flex-row flex-wrap justify-between h-[47rem] tablet:h-[65rem] lg:w-[90%] 4xl:w-[80%] overflow-y-scroll no-scrollbar py-10 px-5 pt-5 lg:pt-32">
                {myCourse?.courses?.map((courseData, index) => (
                  <CourseCard
                    thumbnails={courseData.courseThumbnail}
                    key={index}
                    title={courseData.courseName}
                    description={courseData.courseDescription}
                    courseId={courseData?.courseUid}
                    courseData={courseData}
                    setActiveStep={setActiveStep}
                    setCourseId={setCourseId}
                    myCourseRefetch={myCourseRefetch}
                    courseLanguage={courseData?.language?.[0]?.languageName}
                  />
                ))}
              </div>
            </div>
          )}
          {activeStep === 0 && (
            <CreateCoursePopup
              setActiveStep={setActiveStep}
              setNewCourseId={setNewCourseId}
              courseRefetch={myCourseRefetch}
              courseId={courseId}
            />
          )}
          {activeStep === 1 && (
            <ManageSection
              setActiveStep={setActiveStep}
              courseId={courseId}
              setCurrentSectionId={setCurrentSectionId}
              setSectionData={setSectionData}
              setSectionIndex={setSectionIndex}
              newCourseId={newCourseId}
            />
          )}
          {activeStep === 2 && (
            <ManageChapter
              sectionUid={currentSectionId}
              setActiveStep={setActiveStep}
              currentSectionId={currentSectionId}
              sectionData={sectionData}
              sectionIndex={sectionIndex}
              setCurrentChapterData={setCurrentChapterId}
              setCurrentChapterIndex={setCurrentChapterIndex}
            />
          )}
          {activeStep === 3 && (
            <ManageLecture
              setActiveStep={setActiveStep}
              currentChapterIndex={currentChapterIndex}
              chapterId={currentChapterId}
              currentSectionId={currentSectionId}
              courseId={courseId}
              setCurrentLectureId={setCurrentLectureId}
            />
          )}

          {activeStep === 4 && (
            <ManageQuestion
              setActiveStep={setActiveStep}
              currentChapterIndex={currentChapterIndex}
              currentChapterId={currentChapterId}
              currentSectionId={currentSectionId}
              currentCourseId={courseId}
              currentLectureId={currentLectureId}
            />
          )}
        </div>

        {myCourse?.courses?.length === 0 && activeStep !== 0 && (
          <div className="flex flex-col items-center justify-center absolute top-[35%] left-0 right-0 w-auto">
            <img src={no_data_image} alt="" className="w-[70%] lg:w-[30%]" />
            <p className="text-base text-gray-700">
              You have not created any course yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Course;

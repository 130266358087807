

export const Utils = {
  isCouponExpired: (expiryDate) =>
    Math.floor(Date.now() / 1000) > Date.parse(expiryDate) / 1000,

  formatDate: (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  },

  formatHtml: (data) => {
    const htmlString = data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const text = doc.body.textContent;
    return text;
  },
  getDateAndTime(input) {
    const date = new Date(input);

    // Date components with abbreviated month name
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Time components in 12-hour format with AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Construct date and time strings
    const dateStr = `${day}/${month}/${year}`;
    const timeStr = `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;

    return { date: dateStr, time: timeStr };
  },

  // // Usage
  // const inputDate = "2024-11-11T03:16:49.856Z";
  // const result = getDateAndTime(inputDate);
  // console.log("Date:", result.date);
  // console.log("Time:", result.time);
};

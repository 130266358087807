import React, { useContext, useState } from "react";
import {
  useDirectAssignCourseMutation,
  useGetDirectOrderQuery,
} from "../../redux/ReduxToolkit";
import { AuthContext } from "../../Providers/AuthProvider";
import CustomLoader from "../Loading/Loading";
import { enqueueSnackbar } from "notistack";
import { Utils } from "../../utils/utils";

const AssignCourseTable = () => {
  const { accessToken } = useContext(AuthContext);

  const {
    data: getOrderData,
    isLoading: getOrderDataIsLoading,
    refetch: getOrderDataRefetch,
  } = useGetDirectOrderQuery();

  const [directAssignCourse, { isLoading: directAssignCourseIsLoading }] =
    useDirectAssignCourseMutation();

  const handleDirectAssignCourse = async (courseData) => {
    console.log(courseData?.courseDetails?.coursePricing?.[0]?.duration);
    // Course assignment logic (mutation or API call)

    try {
      const response = await directAssignCourse({
        body: {
          userId: courseData?.studentUid,
          courseId: courseData?.courseDetails?._id,
          validity: courseData?.courseDetails?.coursePricing?.[0]?.duration,
          uniqueId: courseData?.studentId,
          orderId: courseData?.orderId,
        },
        token: accessToken,
      });
      if (response.data) {
        console.log(response.data?.msg);
        enqueueSnackbar(response.data?.msg, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        getOrderDataRefetch();
      }
      if (response.errors) {
        console.log("Mutation errors:", response.errors);
      }
    } catch (err) {
      // Extract status code from the error if it's a network error
      if (err.networkError) {
        const statusCode = err.networkError.statusCode;
        console.log("Network Error Status Code:", statusCode);
      } else {
        console.log("GraphQL Error:", err.message);
      }
    }
  };

  return (
    <div className="overflow-x-auto">
      {(getOrderDataIsLoading || directAssignCourseIsLoading) && (
        <CustomLoader />
      )}
      <div className="max-h-[80vh] lg:max-h-[85vh] overflow-y-auto text-sm">
        {/* Table Body Scroll */}
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="sticky top-0 bg-gray-200 z-10">
            {/* Sticky header */}
            <tr>
              <th className="py-2 px-4 border-b">Sr. No.</th>
              <th className="py-2 px-4 border-b">Course Name</th>
              <th className="py-2 px-4 border-b">Student Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Price</th>
              <th className="py-2 px-4 border-b">Date / Time</th>
              <th className="py-2 px-4 border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {getOrderData?.order.map((course, index) => (
              <tr
                key={index}
                className={`hover:bg-gray-100 text-center ${
                  course?.isCourseAllotted ? "bg-green-300/20" : ""
                }`}
              >
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">
                  {course.courseDetails?.courseName}
                </td>
                <td className="py-2 px-4 border-b">{course.name}</td>
                <td className="py-2 px-4 border-b">{course.email}</td>
                <td className="py-2 px-4 border-b">₹ {course.amount}</td>
                <td className="py-2 px-4 border-b">
                  {Utils?.getDateAndTime(course.directOrderDate)?.date} ({" "}
                  {Utils?.getDateAndTime(course.directOrderDate)?.time})
                </td>

                <td className="py-2 px-4 border-b">
                  {course?.isCourseAllotted ? (
                    <span className="text-green-500 text-xl">✓</span> // Green check mark
                  ) : (
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700 cursor-pointer"
                      onClick={() => handleDirectAssignCourse(course)}
                    >
                      Assign Course
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssignCourseTable;

import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { useGetAllChaptersBySectionIdQuery } from "../../../redux/ReduxToolkit";
import CreateChapterPopup from "./CreateChapterPopup";
import ChapterCard from "./ChapterCard";
import CustomLoader from "../../Loading/Loading";
import { TbArrowNarrowLeft } from "react-icons/tb";

import no_data_image from "../../../assets/no_data(course_component).png";
import { AuthContext } from "../../../Providers/AuthProvider";

function ManageChapter({ setActiveStep, setCurrentChapterData, sectionUid }) {
  const { accessToken } = useContext(AuthContext);

  const [showChapterForm, setShowChapterForm] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [showEditMode, setShowEditMode] = useState(false);
  const [chapterFormData, setChapterFormData] = useState({
    chapterName: "",
    chapterDescription: "",
  });

  const {
    data: getChapterBySectionIdData,
    isLoading,
    refetch: reFetchManageChapter,
  } = useGetAllChaptersBySectionIdQuery({
    id: sectionUid,
    token: accessToken,
  });

  // const [createChapter] = useCreateChapterMutation();

  // console.log("chapter component me section ki id = ", currentSectionId);
  // console.log(
  //   "chapter component me sectionData =  ",
  //   sectionData[sectionIndex]?.sectionContent?.chapter
  // );

  useEffect(() => {
    if (showChapterForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showChapterForm]);

  const handleSelectSectionTitle = (sectionData) => {
    // setShowChapterCard(true);
    setShowDropdown(false);
    setSectionTitle(sectionData?.sectionName);
  };

  const handleSelectCurrentChapter = (chapters, index) => {
    setCurrentChapterData(chapters?.chapterUid);
    setActiveStep(3);
  };

  return (
    <div className=" h-full flex flex-col justify-center items-center w-full  ">
      {isLoading && <CustomLoader />}
      <div className="lg:w-[90%] w-full tablet:w-full flex flex-col justify-center items-center h-full ">
        <div className="  flex items-center justify-between px-5 w-full ">
          <TbArrowNarrowLeft
            className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer  block lg:hidden `}
            onClick={() => setActiveStep(1)}
          />
          <CustomButton
            lable={`Create Chapter`}
            className={`bg-purpleColor text-white  border-none    mt-3  lg:absolute bottom-10 right-5 mx-0`}
            onClick={() => setShowChapterForm(true)}
          />
        </div>

        {getChapterBySectionIdData?.sections?.chapters?.length > 0 ? (
          <div className="flex justify-start flex-wrap gap-2 items-start w-full   py-0 px-5 lg:pt-32  overflow-y-auto no-scrollbar  h-[100vh] pb-52">
            {getChapterBySectionIdData?.sections?.chapters?.map(
              (item, index) => (
                <ChapterCard
                  key={index}
                  thumbnails={item.sectionThumbnail}
                  setChapterFormData={setChapterFormData}
                  title={item?.chapterName}
                  description={item?.chapterDescription}
                  setActiveStep={setActiveStep}
                  setShowEditMode={setShowEditMode}
                  setShowChapterForm={setShowChapterForm}
                  onclick={() => handleSelectCurrentChapter(item, index)}
                  chapterData={item}
                />
              )
            )}
          </div>
        ) : (
          !isLoading && (
            <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
              <div className=" flex justify-center ">
                <img
                  src={no_data_image}
                  alt=""
                  className="w-[50%] lg:w-[30%]"
                />
              </div>
              <p className="text-lg text-gray-700   ">
                You have not created any chapter yet
              </p>
            </div>
          )
        )}

        {(showChapterForm || showEditMode) && (
          <CreateChapterPopup
            refetch={reFetchManageChapter}
            setShowEditMode={setShowEditMode}
            setShowChapterForm={setShowChapterForm}
            sectionId={sectionUid}
            chapterFormData={chapterFormData}
            setChapterFormData={setChapterFormData}
          />
        )}
      </div>
    </div>
  );
}

export default ManageChapter;

import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import {
	MdDelete,
	MdEdit,
	MdOutlineAddComment,
	MdOutlineFileCopy,
	MdOutlinePreview,
	MdPlayCircle,
} from "react-icons/md";
import { PiTelevisionSimple } from "react-icons/pi";
import PreviewQuestion from "./PreviewQuestion";
import { Utils } from "../../../utils/utils";
import parse from "html-react-parser";

function QuestionCard({
	onClick,
	title,
	answerDescription,
	itemKey,
	optionsArray,
	arrayIndex,
	borderColor,
}) {
	let menuRef = useRef();

	const [showMenu, setShowMenu] = useState(false);
	const [publish, setPublish] = useState(false);
	const [previewQuestion, setPreviewQuestion] = useState(false);

	const [pei, setPei] = useState();

	useEffect(() => {
		let handler = (e) => {
			if (!menuRef?.current?.contains(e?.target)) {
				setShowMenu(false);
				// console.log(menuRef.current);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	const menuItems = [
		{
			link: "",
			title: "Edit",
			icon: <MdEdit />,
		},
		{
			link: "",
			title: publish ? "Unpublish" : "Publish",
			icon: publish ? <LuMonitorX /> : <LuMonitorCheck />,
		},

		{
			link: "",
			title: "Preview Question",
			className: "",
			icon: <MdOutlinePreview />,
		},
		{
			link: "",
			title: "Delete",
			className: "",
			icon: <MdDelete />,
		},
	];

	const handleMenuItems = (index, items, e) => {
		e.stopPropagation();

		if (index === 0) {
			setShowMenu(false);
		}
		if (index === 1) {
			// setShowMenu(false);
			setPublish(!publish);
		}
		if (items?.title === "Preview Question") {
			setPreviewQuestion(true);
			setShowMenu(false);
		}

		if (items?.title === "Delete") {
			setShowMenu(false);
		}
	};

	return (
    <div className="flex flex-col  justify-start" ref={menuRef}>
      <div
        className={`w-[20rem] h-[5rem] flex justify-between border rounded-md m-2 cursor-pointer shadow-md  border-l-[5.5px] border-l-orange-500`}
        onClick={onClick}
        key={itemKey}
      >
        <div className="  flex flex-col justify-start items-start relative w-full  p-2 ">
          <BsThreeDotsVertical
            className="absolute right-2  text-xl cursor-pointer z-10"
            onClick={() => setShowMenu(!showMenu)}
          />
          <p className=" mb-3 text-gray-600 capitalize line-clamp-2">
            {Utils.formatHtml(title)}
          </p>

          {showMenu && (
            <ul
              className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-8 z-10`}
            >
              {menuItems?.map((items, index) => (
                <li
                  key={index}
                  className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                  onClick={(e) => handleMenuItems(index, items, e)}
                >
                  <span className="me-4 text-lg text-gray-500 bg-gray-200 p-1.5 rounded-full ">
                    {items?.icon}
                  </span>{" "}
                  {items?.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {previewQuestion && (
        <PreviewQuestion
          setPreviewQuestion={setPreviewQuestion}
          optionsArray={optionsArray}
          questionData={title}
          answerData={answerDescription}
          arrayIndex={arrayIndex}
          rightPopup={`animate-rightPopup`}
        />
      )}
    </div>
  );
}

export default QuestionCard;

import React from "react";
import MenuSidebar from "./crm/MenuSidebar/MenuSidebar";
import ProfileSidebar from "./crm/ProfileSidebar/ProfileSidebar";
import ContentAriea from "./crm/Content-Ariea/Content-Ariea";
import MobileMenuSidebar from "./crm/MenuSidebar/MobileMenuSidebar";

function Educator({ children }) {
  return (
    <div className=" w-full h-auto flex flex-col lg:flex-row bg-gray-200  ">
      <div className="hidden lg:block">
        <MenuSidebar />
      </div>
      <div className="block lg:hidden sticky top-0  bg-gray-200 z-50">
        <div className="block lg:hidden ">
          <MobileMenuSidebar />
        </div>
      </div>
      <ContentAriea></ContentAriea>
      {/* <ProfileSidebar /> */}
    </div>
  );
}

export default Educator;

import React, { useState } from "react";
import CreateNotificaton from "./CreateNotificaton";
import PreviewNotificaton from "./PreviewNotificaton";
import WhatsAppCreateNotification from "./WhatsAppCreateNotification";

function Notificaton() {
  const [showPushNotificationBox, setShowPushNotificationBox] =
    useState("push");
  return (
    <div className="flex flex-col   w-full h-[95vh] overflow-y-auto no-scrollbar p-5 lg:p-10">
      <div className="flex flex-row my-5 self-start border rounded-full ">
        <p
          className={`!py-2 !px-5 mx-auto rounded-full cursor-pointer text-sm  ${
            showPushNotificationBox === "push"
              ? "font-medium bg-green-600 rounded-r-none text-white"
              : ""
          }`}
          onClick={() => setShowPushNotificationBox("push")}
        >
          Push Notification
        </p>
        <p
          className={`!py-2 !px-5 mx-auto rounded-full cursor-pointer text-sm ${
            showPushNotificationBox === "whatsApp"
              ? "font-medium bg-green-600 text-white rounded-l-none"
              : ""
          }`}
          onClick={() => setShowPushNotificationBox("whatsApp")}
        >
          WhatsApp Notification
        </p>
      </div>
      {showPushNotificationBox === "push" && (
        <div>
          <CreateNotificaton />
        </div>
      )}
      {showPushNotificationBox === "whatsApp" && (
        <div className="">
          <WhatsAppCreateNotification />
        </div>
      )}
      <div>
        <PreviewNotificaton />
      </div>
    </div>
  );
}

export default Notificaton;

import React from "react";
import AssignCourseTable from "./AssignCourseTable";

function AssignCourse() {
  const courseData = [
    {
      courseId: "1",
      courseName: "Physics 101",
      stName: "John Doe",
      email: "john@example.com",
      price: "$100",
    },
    {
      courseId: "2",
      courseName: "Mathematics 201",
      stName: "Jane Smith",
      email: "jane@example.com",
      price: "$120",
    },
    // More course entries...
  ];
  const handleAssignCourse = (courseId) => {
    console.log(`Assign course with ID: ${courseId}`);
    // Add your logic to assign the course here
  };
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Course Assignment</h1>
      <AssignCourseTable courses={courseData} onAssignCourse={handleAssignCourse} />
    </div>
  );
}

export default AssignCourse;

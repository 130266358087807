 import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import CustomInput from "../../../Pages/Shared/CustomInput/CustomInput";
import CustomTextarea from "../../../Pages/Shared/CustomTextarea/CustomTextarea";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";

function CourseCoupon({
  zoomInPopup,
  setShowCouponPopup,
  setCouponList,
  couponList,
}) {
  const [couponName, setCouponName] = useState("");
  const [couponDescription, setCouponDescription] = useState("");
  const [couponRate, setCouponRate] = useState(0);
  const [validTill, onChange] = useState(new Date());
  const [showCalender, setShowCalender] = useState(false);
  const [singleUse, setSingleUse] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState(false);

  const handleSave = () => {
    if (couponName === null || couponName === undefined || couponName.length === 0) {
      setError(true);
      setErrMsg("Coupan Name is required !");
      return;
    }
    if (couponDescription === null || couponDescription === undefined || couponDescription.length === 0) {
      setError(true);
      setErrMsg("Coupan Description is required !");
      return;
    }
    if (couponRate === null || couponRate === undefined || couponRate.length === 0) {
      setError(true);
      setErrMsg("coupon Rate is required !");
      return;
    }

    const copy = [
      ...couponList,
      {
        couponName: couponName,
        couponDescription: couponDescription,
        couponRate: parseInt(couponRate),
        validTill: validTill,
        singleUse: singleUse,
      },
    ];

    console.log("copy", copy);

    setCouponList(copy);
    setShowCouponPopup(false);
    setCouponName("");
    setCouponDescription("");
    setCouponRate(0);
    setSingleUse(false);
    onChange(null);
  };

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] fixed top-0 left-0 flex justify-center items-center ">
      <div
        className={`bg-white w-[95%] lg:w-[30%] tablet:w-[60%] p-5 lg:p-10 relative rounded-md shadow-lg ${zoomInPopup}`}
      >
        <div className="text-3xl ms-auto  mb-5 ">
          <AiFillCloseCircle
            className="cursor-pointer absolute top-3 right-3"
            onClick={() => setShowCouponPopup(false)}
          />
        </div>

        {error && (
          <div className="bg-red-400 rounded-md p-5 py-3 mb-3 text-sm text-white ">
            {errMsg}
          </div>
        )}

        <div className="">
          <p className="">Coupon Name</p>
          <CustomInput
            className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-400 !mt-0 text-sm`}
            placeholder={`Enter Coupon Name`}
            onChange={(e) => {
              setCouponName(e.target.value);
              setError(false);
            }}
          />
        </div>
        <div className="">
          <p className="">Coupon Description</p>
          <CustomTextarea
            className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-400 !mt-0 text-sm`}
            placeholder={`Enter Coupon Description`}
            onChange={(e) => {
              setCouponDescription(e.target.value);
              setError(false);
            }}
          />
        </div>
        <div className="">
          <p className=""> Coupon Rate</p>
          <CustomInput
            type="number"
            className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-400 !mt-0 text-sm`}
            placeholder={`Enter Coupon Rate (Default value 0)`}
            onChange={(e) => {
              setCouponRate(e.target.value);
              setError(false);
            }}
          />
        </div>

        <div>
          <p className="mb-5">Coupon Expiry</p>
          <div className="">
            <p
              className={`!border !rounded-md !py-2 !border-gray-200 !px-5 !mt-0 text-sm cursor-pointer`}
              onClick={() => setShowCalender(!showCalender)}
            >
              {validTill?.toLocaleDateString()?.toString()}
            </p>
          </div>
          <p className=""></p>
          {showCalender && (
            <div className="">
              <Calendar
                onChange={(e) => {
                  onChange(e);
                  setShowCalender(false);
                }}
                value={validTill}
                className={`border-none`}
              />
            </div>
          )}
        </div>
        <div className="flex items-center my-5">
          <input
            type="checkbox"
            className=""
            onClick={() => setSingleUse(!singleUse)}
          />{" "}
          <p className="ms-2 text-sm font-normal">Single Use</p>
        </div>

        <div className="flex justify-end mt-5">
          <CustomButton
            lable={`Cancel`}
            className={`w-[5rem] !px-0 bg-red-500 text-white border-none `}
            onClick={() => setShowCouponPopup(false)}
          />
          <CustomButton
            lable={`Save`}
            className={`w-[5rem] !px-0 bg-green-500 text-white border-none !me-0 `}
            onClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}

export default CourseCoupon;

import React, { useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";

import ShortsData from "./ShortsData";
import AddNewShorts from "./AddNewShorts";

function ShortsVideos() {
  const [showUploadVideoPopup, setShowUploadVideoPopup] = useState(false);
  return (
    <div className="flex flex-col  items-center pt-5  ">
      <div className="flex   ">
        <CustomButton
          lable={`Add New Shorts`}
          className={`  !rounded-full py-3 !px-10 !font-normal !mx-0`}
          onClick={() => setShowUploadVideoPopup(true)}
        />
      </div>

      {showUploadVideoPopup && (
        <AddNewShorts
          setShowUploadVideoPopup={setShowUploadVideoPopup}
          zoomInPopup={`animate-zoomInPopup`}
        />
      )}

      <div className="w-full  mt-10 flex justify-center px-10">
        <div className="h-[70vh] lg:h-[80vh] w-full lg:w-[500px]  ">
          <ShortsData />
        </div>
      </div>
    </div>
  );
}

export default ShortsVideos;

import React, { useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { FiPlus } from "react-icons/fi";
import SectionCard from "./SectionCard";
import { useGetAllSectionsQuery } from "../../../redux/ReduxToolkit";
import CreateSectionForm from "./CreateSectionForm";
import CustomLoader from "../../Loading/Loading";

import no_data_image from "../../../assets/no_data(course_component).png";
import { TbArrowNarrowLeft } from "react-icons/tb";

function ManageSection({
  setActiveStep,
  courseId,
  setCurrentSectionId,
  setSectionData,
  setSectionIndex,
}) {
  const [showCreateSectionForm, setShowCreateSectionForm] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [sectionFormData, setSectionFormData] = useState({
    sectionName: "",
    sectionDescription: "",
  });

  const {
    data: getAllSectionData,
    refetch: sectionRefetch,
    isLoading,
  } = useGetAllSectionsQuery(courseId);

  const handleShowCreateSection = () => {
    setShowCreateSectionForm(!showCreateSectionForm);
  };

  const handleSectionCardClick = (sectionData, index) => {
    setCurrentSectionId(sectionData.sectionUid);
    setActiveStep(2);
  };

  return (
    <div className="h-full  flex justify-center w-full  ">
      {isLoading && <CustomLoader />}
      <div className="lg:w-[90%] w-full tablet:w-full flex flex-col justify-center items-center  ">
        {!showCreateSectionForm && (
          <div className="lg:w-[90%] w-full h-full ">
            <div className="flex items-center justify-between w-full mt-5 px-5    ">
              <TbArrowNarrowLeft
                className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer block lg:hidden `}
                onClick={() => setActiveStep(null)}
              />
              <CustomButton
                lable={`Create Section `}
                hasIcon={true}
                className={`lg:absolute bottom-3 right-5 !mx-0 bg-purpleColor text-white  border-none   font-normal `}
                onClick={() => handleShowCreateSection()}
              >
                <FiPlus className="text-xl" />
              </CustomButton>
            </div>
            <div className="h-[50rem] lg:h-[75vh] overflow-y-auto no-scrollbar ">
              {getAllSectionData?.sections?.length > 0 ? (
                <div className="flex flex-wrap justify-start gap-2 w-full   px-5  py-10 lg:pt-32">
                  {getAllSectionData?.sections?.map((sectionData, index) => (
                    <SectionCard
                      key={index}
                      thumbnails={sectionData.sectionThumbnail}
                      setSectionFormData={setSectionFormData}
                      title={sectionData?.sectionName}
                      description={sectionData?.sectionDescription}
                      setActiveStep={setActiveStep}
                      showEditMode={showEditMode}
                      setShowCreateSectionForm={setShowCreateSectionForm}
                      setShowEditMode={setShowEditMode}
                      onclick={() => {
                        handleSectionCardClick(sectionData, index);
                      }}
                    />
                  ))}
                </div>
              ) : (
                !isLoading && (
                  <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
                    <div className=" flex justify-center ">
                      <img
                        src={no_data_image}
                        alt=""
                        className="w-[50%] lg:w-[30%]"
                      />
                    </div>
                    <p className="text-lg text-gray-700   ">
                      You have not created any section yet
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        {(showCreateSectionForm || showEditMode) && (
          <CreateSectionForm
            setActiveStep={setActiveStep}
            courseId={courseId}
            sectionRefetch={sectionRefetch}
            setShowCreateSectionForm={setShowCreateSectionForm}
            showEditMode={showEditMode}
            setShowEditMode={setShowEditMode}
            showCreateSectionForm={showCreateSectionForm}
            sectionFormData={sectionFormData}
          />
        )}
      </div>
    </div>
  );
}

export default ManageSection;

import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const GradientLineChart = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartContainer.current.getContext("2d");

    // Gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(82,70,162, 0.5)"); // green1
    gradient.addColorStop(0.5, "rgba(82,70,162, 0.2)"); // green2
    gradient.addColorStop(1, "rgba(82,70,162, 0)"); // green3

    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "My Dataset",
            data: [10, 30, 20, 40, 35, 50, 30],
            borderColor: "rgb(82,70,162)", // border green
            backgroundColor: gradient, // Apply the gradient as background color
            borderWidth: 4, // Increased line width
            borderCapStyle: "round", // Use round line caps for smooth bezier curves
            cubicInterpolationMode: "monotone", // Use a monotone cubic interpolation
            fill: true, // Fill the area below the line
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, // Disable aspect ratio to use custom dimensions
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Hide the y-axis grid lines
            },
            display: false,
          },
          x: {
            grid: {
              display: false, // Hide the x-axis grid lines
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              font: {
                size: 16, // Adjust the legend font size
              },
            },
          },
          tooltip: {
            titleFont: {
              size: 16, // Adjust the tooltip title font size
            },
            bodyFont: {
              size: 14, // Adjust the tooltip body font size
            },
          },
        },
      },
    });

    return () => {
      // Clean up: destroy chart instance when component unmounts
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="h-[300px] w-full ">
      <canvas ref={chartContainer} />
    </div>
  );
};

export default GradientLineChart;

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import {
	MdDelete,
	MdEdit,
	MdOutlineAddComment,
	MdOutlineFileCopy,
	MdPlayCircle,
} from "react-icons/md";
import { PiTelevisionSimple } from "react-icons/pi";
import { useEffect, useRef, useState } from "react";
import UploadLecture from "./UploadLecture";
import PreviewLecture from "./PreviewLecture";
import UploadPdf from "./UploadPdf";
import { IoCloseSharp } from "react-icons/io5";
import PreviewPdf from "./PreviewPdf";
import { IoIosPhonePortrait } from "react-icons/io";
import {
  useMarkLectureAsIntroMutation,
  useMarkLectureAsTrailMutation,
} from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";
import CustomLoader from "../../Loading/Loading";

function LectureCard({
  title,
  description,
  handleClickLectureCard,
  thumbnails,
  setActiveStep,
  lectureAllData,
  chapterId,
  sectionId,
  courseId,
  setShowForm,
  showLecturePreviewBox,
  setShowLecturePreviewBox,
  refetchAllLectureByChapter,
  currentLectureUid,
  currentLectureAllData,
}) {
  const [showUploadPdfBox, setShowUploadPdfBox] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [publish, setPublish] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [showPreviewBox, setShowPreviewBox] = useState(false);
  const [showPreviePdfBox, setShowPreviewPdfBox] = useState(false);
  const [isDemoLecture, setIsDemoLecture] = useState(false);
  const [isFreeLecture, setIsFreeLecture] = useState(false);
  let menuRef = useRef();

  const [markLectureAsIntro, { isLoading: markLectureAsIntroIsLoading }] =
    useMarkLectureAsIntroMutation();
  const [markLectureAsTrail, { isLoading: markLectureAsTrailIsLoading }] =
    useMarkLectureAsTrailMutation();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (currentLectureAllData) {
      setIsDemoLecture(currentLectureAllData?.isDemoLecture);
      setIsFreeLecture(currentLectureAllData?.isFreeLecture);
    }
  }, [currentLectureAllData]);

  const menuItems = [
    // {
    //   link: "",
    //   title: "Edit",
    //   icon: <MdEdit />,
    // },
    // {
    //   link: "",
    //   title: publish ? "Unpublish" : "Publish",2
    //   icon: publish ? <LuMonitorX /> : <LuMonitorCheck />,
    // },

    // {
    //   link: "",
    //   title: "Question Bank",
    //   icon: <MdOutlineAddComment />,
    // },

    {
      link: "",
      title: "Upload Videos/Lecture",
      icon: <MdPlayCircle />,
    },

    {
      link: "",
      title: "Preview Lecture",
      icon: <PiTelevisionSimple />,
    },
    {
      link: "",
      title: "Add Notes",
      icon: <MdOutlineFileCopy />,
    },
    {
      link: "",
      title: "Preview Notes",
      icon: <IoIosPhonePortrait />,
    },
    {
      link: "",
      title: "Demo Lecture",
      icon: <MdOutlineFileCopy />,
    },
    {
      link: "",
      title: "Free Lecture",
      icon: <MdOutlineFileCopy />,
    },

    // {
    //   link: "",
    //   title: "Delete",
    //   className: "",
    //   icon: <MdDelete />,
    // },
  ];

  const handleMenuItems = (index, items, e) => {
    e.stopPropagation();

    if (items?.title === "Edit") {
      setShowMenu(false);
      setShowForm(true);
    }
    if (index === 1) {
      // setShowMenu(false);
      setPublish(!publish);
    }
    if (items?.title === "Question Bank") {
      setActiveStep(4);
    }
    if (items?.title === "Add Notes") {
      setShowUploadPdfBox(true);
      setShowMenu(false);
    }
    if (items?.title === "Upload Videos/Lecture") {
      setShowUploadBox(true);
      setShowMenu(false);
    }
    if (items?.title === "Preview Lecture") {
      setShowPreviewBox(true);
      setShowMenu(false);
    }
    if (items?.title === "Preview Notes") {
      setShowPreviewPdfBox(true);
      setShowMenu(false);
    }

    if (items?.title === "Delete") {
      setShowMenu(false);
    }
    if (items?.title === "Demo Lecture") {
      setIsFreeLecture(!isFreeLecture);
      handleDemoLecture();
      setShowMenu(false);
    }

    if (items?.title === "Free Lecture") {
      setIsDemoLecture(!isDemoLecture);
      handleFreeLecture();

      setShowMenu(false);
    }
  };

  const handleFreeLecture = async () => {
    try {
      const response = await markLectureAsTrail({
        body: {
          lectureUid: currentLectureUid,
          isFreeLecture: !currentLectureAllData?.isFreeLecture,
        },
      });
      console.log("free Lecture response", response);
      if (response) {
        enqueueSnackbar(response?.data?.msg, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
        refetchAllLectureByChapter();
      } else {
        console.log("Something went worng !");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const handleDemoLecture = async () => {
    try {
      const response = await markLectureAsIntro({
        body: {
          lectureUid: currentLectureUid,
          isDemoLecture: !currentLectureAllData?.isDemoLecture,
        },
      });
      console.log("demo Lecture response", response);

      if (response) {
        enqueueSnackbar(response?.data?.msg, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
        refetchAllLectureByChapter();
      } else {
        console.log("Something went worng !");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  // console.log("isDemoLecture", isDemoLecture);
  // console.log("isFreeLecture", isFreeLecture);

  return (
    <div
      className={` w-[90%] md:w-[20rem] min-h-[5rem] h-[5rem] mx-auto mb-5 self-start flex  border-r-none border-y-none rounded-md my-2 cursor-pointer   border-l-[8px] ps-2 shadow-no-border-shadow-2 relative
        ${
          (lectureAllData?.isPublished === false ||
            lectureAllData?.lecture === undefined) &&
          "border-l-red-500"
        }
        ${
          lectureAllData?.lecture === undefined &&
          lectureAllData?.isPublished === true &&
          "border-l-yellow-500"
        }
        ${
          lectureAllData?.isPublished === true &&
          (lectureAllData?.lecture !== undefined ||
            lectureAllData?.lecture !== null) &&
          "border-l-green-500"
        }
    
        
        `}
      onClick={handleClickLectureCard}
      ref={menuRef}
    >
      {(markLectureAsIntroIsLoading || markLectureAsTrailIsLoading) && (
        <CustomLoader />
      )}

      <div className=" flex justify-start ">
        {thumbnails && (
          <img
            src={`https://axg6mefkv4.execute-api.ap-south-1.amazonaws.com/aws/serverless/optimize?url=https://ddwett0ep4121.cloudfront.net/${thumbnails?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=200&height=100&quality=10`}
            alt=""
            className="rounded-lg"
          />
        )}
      </div>
      <div className="  flex flex-col justify-start items-start  w-full  p-1 pe-7  ">
        <p className="   text-[#313639] capitalize line-clamp-1 font-medium ">
          {title}
        </p>
        <p className="text-xs pe-10 text-gray-400 normal-case line-clamp-2">
          {description}
        </p>
        <BsThreeDotsVertical
          className="absolute right-2  text-xl cursor-pointer z-10"
          onClick={(e) => {
            setShowMenu(!showMenu);
          }}
        />
        {showMenu && (
          <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center fixed top-0 left-0 z-50 cursor-default">
            <div
              className={` animate-rightPopup  h-[100vh] bg-white shadow-lg w-[20rem]     overflow-y-scroll  relative`}
            >
              <div className="w-full flex justify-end">
                <IoCloseSharp
                  className={`  text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 m-5 `}
                  onClick={(e) => {
                    setShowMenu(false);
                  }}
                />
              </div>
              <div className="">
                {menuItems?.map((items, index) => (
                  <p
                    key={index}
                    className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                    onClick={(e) => handleMenuItems(index, items, e)}
                  >
                    <span className="mx-4 text-lg text-gray-500 bg-gray-200 p-1.5 rounded-full">
                      {items?.icon}
                    </span>{" "}
                    {items?.title}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {showUploadPdfBox && (
        <UploadPdf
          zoomInPopup={`animate-zoomInPopup`}
          setShowUploadPdfBox={setShowUploadPdfBox}
          showUploadPdfBox={showUploadPdfBox}
          lectureAllData={lectureAllData}
          courseId={courseId}
          sectionId={sectionId}
          chapterId={chapterId}
          refetchAllLectureByChapter={refetchAllLectureByChapter}
        />
      )}
      {showUploadBox && (
        <UploadLecture
          zoomInPopup={`animate-zoomInPopup`}
          setShowUploadBox={setShowUploadBox}
          showUploadBox={showUploadBox}
          lectureAllData={lectureAllData}
          courseId={courseId}
          sectionId={sectionId}
          chapterId={chapterId}
          setShowLecturePreviewBox={setShowLecturePreviewBox}
        />
      )}
      {showPreviewBox && (
        <PreviewLecture
          rightPopup={`animate-rightPopup`}
          lectureAllData={lectureAllData}
          setShowPreviewBox={setShowPreviewBox}
        />
      )}
      {showPreviePdfBox && (
        <PreviewPdf
          bottomPopup={`animate-bottomPopup`}
          lectureAllData={lectureAllData}
          setShowPreviewPdfBox={setShowPreviewPdfBox}
        />
      )}
    </div>
  );
}

export default LectureCard;

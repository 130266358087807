import React, { useContext, useEffect, useRef, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaBook } from "react-icons/fa6";
import Lottie from "lottie-react";
import BrandLogo from "../../../Pages/Shared/BrandLogo/BrandLogo";
import no_thumbnail_bg from "../../../assets/thumbnail_bg_ripalEffect.json";
import CustomLoader from "../../Loading/Loading";
import { usePublishChapterMutation } from "../../../redux/ReduxToolkit";
import { AuthContext } from "../../../Providers/AuthProvider";
import SectionImageCard from "../../CreateThumbnail/SectionImageCard";
import CourseImageCard from "../../CreateThumbnail/CourseImageCard";

function ChapterCard({
  title,
  description,
  onclick,
  setActiveStep,
  setShowEditMode,
  setShowChapterForm,
  setChapterFormData,
  thumbnails,
  chapterData,
}) {
  const { accessToken } = useContext(AuthContext);

  const [showMenu, setShowMenu] = useState(false);
  const [publish, setPublish] = useState(false);
  const [isPublishedCourseStatus, setIsPublishedCourseStatus] = useState(
    chapterData?.isPublished
  );
  let menuRef = useRef();
  const [publishChapter, { isLoading: publishChapterIsLoading }] =
    usePublishChapterMutation();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowMenu(false);
        // console.log(menuRef.current);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const menuItems = [
    {
      link: "",
      title: "Edit",
      icon: <MdEdit />,
    },
    {
      link: "",
      title: isPublishedCourseStatus ? "Unpublish" : "Publish",
      icon: isPublishedCourseStatus ? <LuMonitorX /> : <LuMonitorCheck />,
    },

    {
      link: "",
      title: "Delete",
      className: "text-red-500",
      icon: <MdDelete />,
    },
  ];

  const handleMenuItems = (index, e, items) => {
    e.stopPropagation();

    if (items?.title === "Edit") {
      setShowMenu(false);
      setShowEditMode(true);
      setShowChapterForm(true);
      setChapterFormData({
        chapterName: title,
        chapterDescription: description,
      });
      return;
    }
    if (index === 1) {
      e.stopPropagation();

      setShowMenu(false);
      setIsPublishedCourseStatus(!isPublishedCourseStatus);
      handlePublishChapter();
    }

    if (items?.title === "Delete") {
      setShowMenu(false);
      return;
    }
  };

  console.log(chapterData?.isPublished);

  const handlePublishChapter = async () => {
    try {
      const response = await publishChapter({
        body: {
          chapterId: chapterData?.chapterUid,
          status: !isPublishedCourseStatus,
        },
        token: accessToken,
      });
      if (response.data) {
        console.log(response.data);
      }

      if (response.errors) {
        console.log("Mutation errors:", response.errors);
      } else {
        // Extracting status code if available in response headers (uncommon)
        const statusCode = response?.http?.status || response?.networkStatus;
        console.log("Status Code:", statusCode);
      }
    } catch (err) {
      // Extract status code from the error if it's a network error
      if (err.networkError) {
        const statusCode = err.networkError.statusCode;
        console.log("Network Error Status Code:", statusCode);
      } else {
        console.log("GraphQL Error:", err.message);
      }
    }
  };

  return (
    <div
      className="w-full tablet:w-[49%] lg:w-[32%] 4xl:w-[24%] flex flex-col   rounded-md  p-3 my-2  shadow-no-border-shadow-2 cursor-pointer relative"
      onClick={onclick}
      ref={menuRef}
    >
      {publishChapterIsLoading && <CustomLoader />}
      {isPublishedCourseStatus ? (
        <div className=" absolute top-1 left-1 bg-green-500 px-4 py-1 text-xsm  rounded-full text-white z-40">
          Published
        </div>
      ) : (
        <div className=" absolute top-1 left-1 bg-red-500 px-4 py-1 text-xsm  rounded-full text-white z-40">
          Unpublished
        </div>
      )}
      <div className=" flex justify-start ">
        {/* {thumbnails ? (
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_0Xr9NGJgOcmUn3SKp_5iZg4vHWrOU5I_w&usqp=CAU"
            alt=""
          />
        ) : (
          <div className="bg-neutral-100 lg:h-full w-full relative rounded-md h-[150px]">
            <Lottie animationData={no_thumbnail_bg} className=" h-full " />
            <BrandLogo
              className={`absolute top-0 bottom-0 my-auto left-0 right-0 mx-auto w-[3rem] h-[3rem] translate-y-0 `}
            />
          </div>
        )} */}
        {/* <SectionImageCard
          imageUrl={require("../../../assets/ThumbnailsImages/style7.png")}
          backgroundColor="bg-gray-100"
          heading={title}
          contentBox={false}
        /> */}
        <CourseImageCard
          imageUrl={require("../../../assets/ThumbnailsImages/style7.png")}
          backgroundColor="bg-gray-100"
          heading={title}

          // config={imageStyle?.STYLE_1}
        />
      </div>
      <div className="w-full   pt-1 flex flex-col justify-start items-start relative mt-3 ">
        {/* <p className=" text-lg mb-3 text-gray-600 capitalize line-clamp-2 lg:line-clamp-1 w-[90%] ">
          {title}
        </p> */}
        <p className="text-xs pe-10 text-gray-500 line-clamp-3 capitalize">
          {description}
        </p>
        <BsThreeDotsVertical
          className="absolute right-0  text-xl cursor-pointer z-10"
          onClick={(e) => {
            setShowMenu(!showMenu);
            e.stopPropagation();
          }}
        />
        {showMenu && (
          <ul
            className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-5 z-20`}
          >
            {menuItems?.map((items, index) => (
              <li
                key={index}
                className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                onClick={(e) => handleMenuItems(index, e, items)}
              >
                <span className="me-4 text-lg text-gray-700 bg-gray-200 p-1.5 rounded-full">
                  {items?.icon}
                </span>{" "}
                {items?.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default ChapterCard;

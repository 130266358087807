import React, { useEffect, useRef, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import { MdDelete, MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import { FaBook } from "react-icons/fa6";
import DeletePopup from "../../DeletePopup/DeletePopup";
import UpdateCourse from "../UpdateCourse/UpdateCourse";
import { ApiConstant } from "../../../Api/ApiConstant";
import { usePublishCourseMutation } from "../../../redux/ReduxToolkit";
import CustomLoader from "../../Loading/Loading";
import CreateThumbnail from "../../CreateThumbnail/CreateThumbnail";
import CourseImageCard from "../../CreateThumbnail/CourseImageCard";

/**
 * Renders a course card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the course.
 * @param {string} props.description - The description of the course.
 * @param {Function} props.onClick - The click event handler for the card.
 * @param {Array} props.thumbnails - The array of thumbnail images for the course.
 * @returns {JSX.Element} The rendered course card component.
 */

const imageStyle = {
  STYLE_1: {
    className: "flex flex-row h-[10rem] ",
  },
  STYLE_2: {
    className: "flex flex-row-reverse h-[10rem] w-[18rem]",
  },
};

function CourseCard({
  title,
  description,
  thumbnails,
  setActiveStep,
  courseId,
  courseData,
  handleShowEditForm,
  setCourseId,
  myCourseRefetch,
  courseLanguage,
}) {
  let menuRef = useRef();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [iconColor, setIconColor] = useState("");
  const [showUpdateCoursePopup, setShowUpdateCoursepopup] = useState(false);
  const [isPublishedCourseStatus, setIsPublishedCourseStatus] = useState(
    courseData?.isPublished
  );

  const [publishCourse, { isLoading: publishCourseIsLoading }] =
    usePublishCourseMutation();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowMenu(false);
        // console.log(menuRef.current);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const menuItems = [
    {
      link: "",
      title: "Edit",
      icon: <MdModeEditOutline />,
    },
    {
      link: "",
      title: isPublishedCourseStatus ? "Unpublish" : "Publish",
      icon: isPublishedCourseStatus ? <LuMonitorX /> : <LuMonitorCheck />,
    },

    {
      link: "",
      title: "Delete",
      className: "text-red-500",
      icon: <MdOutlineDelete />,
    },
  ];

  const handleMenuItems = (index, e, items) => {
    // e.preventDefault();
    // e.stopPropagation();
    // console.log(items);

    if (items?.title === "Edit") {
      e.stopPropagation();
      setShowMenu(false);
      setShowUpdateCoursepopup(true);
    }
    if (index === 1) {
      e.stopPropagation();
      setShowMenu(false);

      // setShowMenu(false);
      setIsPublishedCourseStatus(!isPublishedCourseStatus);
      handlePublishCourse();
    }

    if (items?.title === "Delete") {
      e.stopPropagation();

      setShowMenu(false);
      setShowDeletePopup(true);
      // console.log(courseId);
    }
  };

  const handleCardClick = () => {
    setCourseId(courseId);
    setActiveStep(1);
    // console.log("card click");
  };

  // const handlePublished = () => {
  //   console.log("courseId", courseId);
  //   console.log("call huaaaa / ");
  //   publishCourse({
  //     body: {
  //       courseId: courseId,
  //       status: isPublishedCourseStatus,
  //     },
  //   });
  //   console.log(status);
  //   myCourseRefetch();
  // };
  const handlePublishCourse = async () => {
    try {
      const response = await publishCourse({
        body: {
          courseId: courseId,
          status: !isPublishedCourseStatus,
        },
      });
      if (response.data) {
        console.log(response.data);
      }

      if (response.errors) {
        console.log("Mutation errors:", response.errors);
      } else {
        // Extracting status code if available in response headers (uncommon)
        const statusCode = response?.http?.status || response?.networkStatus;
        console.log("Status Code:", statusCode);
      }
    } catch (err) {
      // Extract status code from the error if it's a network error
      if (err.networkError) {
        const statusCode = err.networkError.statusCode;
        console.log("Network Error Status Code:", statusCode);
      } else {
        console.log("GraphQL Error:", err.message);
      }
    }
  };

  return (
    <div
      className=" tablet:w-[49%] lg:w-[32%] 4xl:w-[24%]    flex flex-col  h-[16rem] bg-gray-50  rounded-xl   my-2  cursor-pointer shadow-no-border-shadow-3    relative"
      onClick={() => handleCardClick()}
      ref={menuRef}
    >
      {publishCourseIsLoading && <CustomLoader />}
      {isPublishedCourseStatus ? (
        <div className=" absolute top-0 left-0 bg-green-500 px-4 py-1 text-xsm  rounded-full text-white z-30">
          Published
        </div>
      ) : (
        <div className=" absolute top-0 left-0 bg-red-500 px-4 py-1 text-xsm  rounded-full text-white z-30 ">
          Unpublished
        </div>
      )}
      {/* <div className=" w-full  flex flex-col justify-start lg:h-[200px]  ">
          {thumbnails?.[0]?.resourceId &&
          thumbnails?.[0]?.resourceId.length > 0 ? (
            <img
              onError={(e) => {
                e.currentTarget.src = `https://xper-api.phynlabz.com/service/utility/resizeImage?url=https://designshack.net/wp-content/uploads/placeholder-image.png}?auto=compress&cs=tinysrgb&dpr=1&width=300&height=200&quality=80`;
              }}
              // src={`${process.env.REACT_APP_IMAGE_OPTIMIZATION_URL}${process.env.REACT_APP_CLOUD_FRONT_URL}/${thumbnails?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=200&height=100&quality=10`}
              src={`${ApiConstant.IMAGE_OPTIMIZATION_URL}${ApiConstant?.CLOUD_FRONT_URL}${thumbnails?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=300&height=200&quality=80`}
              alt=""
              className="rounded-2xl "
            />
          ) : (
            <div
              className="
            flex justify-center items-center
            h-[100px] w-[200px] rounded-lg
            bg-gradient-to-r from-gray-700 via-gray-900 to-black
          "
            >
              <p className="text-white text-center text-xs font-thin self-center">
                No Thumbnail
              </p>
            </div>
          )}
        </div> */}
      {/* <CreateThumbnail /> */}
      <CourseImageCard
        imageUrl={require("../../../assets/ThumbnailsImages/style17.png")}
        backgroundColor="bg-gray-100"
        heading={title}
        // config={imageStyle?.STYLE_1}
        language={courseLanguage}
      />
      <div className="w-full py-2 pb-10 lg:py-0 flex flex-col justify-between items-start   lg:mt-5 px-2 ">
        <div className="flex flex-col">
          {/* <p className="text-sm lg:text-base mb-3 text-[#313639] capitalize line-clamp-2 lg:line-clamp-1  ">
              {title}
            </p> */}
          <p className="text-xs  pe-10 text-gray-400  w-full  line-clamp-4 capitalize">
            {description}
          </p>
        </div>
        <div className=" w-full absolute bottom-2 flex flex-row-reverse items-center">
          <BsThreeDotsVertical
            className={`  text-3xl p-1 me-5  cursor-pointer z-30 hover:bg-gray-300/50 rounded-md ${
              showMenu ? "bg-gray-200" : ""
            } `}
            // onMouseEnter={(e) => {
            //   e.stopPropagation();
            //   setShowMenu(true);
            // }}
            // onMouseLeave={(e) => {
            //   e.stopPropagation();
            //   setShowMenu(false);
            // }}
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          />
          {showMenu && (
            <ul
              className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-5 z-20 `}
            >
              {menuItems?.map((items, index) => (
                <li
                  key={index}
                  className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                  onClick={(e) => handleMenuItems(index, e, items)}
                >
                  <span className="me-4 text-lg text-gray-700 bg-gray-200 p-1.5 rounded-full">
                    {items?.icon}
                  </span>{" "}
                  {items?.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {showDeletePopup && (
        <DeletePopup
          zoomInPopup={`animate-zoomInPopup`}
          title={`Delete Course`}
          description={`Are you sure this item will be removed ? Once deleted this item will not be returned. `}
          cancelOnClick={() => setShowDeletePopup(false)}
        />
      )}
      {showUpdateCoursePopup && (
        <UpdateCourse
          setShowUpdateCoursepopup={setShowUpdateCoursepopup}
          rightPopup={`animate-rightPopup`}
          courseId={courseId}
          setActiveStep={setActiveStep}
        />
      )}
    </div>
  );
}

export default CourseCard;

import React from "react";

const ReceiverChatBubble = ({ message }) => {
  return (
    <div className="flex w-full mb-4 justify-start">
      <div className="max-w-xs md:max-w-md p-3 rounded-lg text-sm shadow-lg bg-gray-100 text-gray-900 rounded-bl-none">
        {message}
        <span className="block text-xs text-right mt-1 opacity-75">
          {new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>
    </div>
  );
};

export default ReceiverChatBubble;

import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function CustomDropdown({ options, type }) {
  const [dropdownValue, setDropdownValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setDropdownValue(`select ${type}`);
  }, []);

  return (
    <div className="flex flex-col relative">
      <div className="">
        {/* <p className=" mb-3">Select Duration</p> */}
        <div
          className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer text-gray-800"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <p className="capitalize">{dropdownValue} </p>

          <MdOutlineKeyboardArrowDown
            className={`text-2xl duration-300 ${
              showDropdown ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {showDropdown && (
          <ul className=" shadow-lg bg-white py-3 mx-1 absolute z-10 left-0 right-0 overflow-y-auto h-[10rem]  text-gray-800">
            {options?.map((option, index) => (
              <li
                key={index}
                className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setDropdownValue(option?.title);
                  setShowDropdown(false);
                }}
              >
                {option?.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CustomDropdown;

import React, { useEffect, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Shorts.css";

// import required modules
import { Pagination } from "swiper/modules";

function ShortsData() {
  const swiperRef = useRef(null);
  const CourseCardData = [
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE-nfA9jB57Oz5XpxraurfhidVDSYBfBx6kwe1QHfbpbq80ZJhCgyuMe8aymVLwO3Tr6s&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQknSmbEPf0mZwF0XEKeor69VUxQqXSuFaCEdpcG7wsgyqclKYjMwIsw38NvnOW2w44Muc&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-4",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5-ZaOEm5Vu6cgOVCHmUeg-uBLylVUlbeO3t8SqMywoyiHsgu_Hgr5abaLinxDnyNOzyA&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE-nfA9jB57Oz5XpxraurfhidVDSYBfBx6kwe1QHfbpbq80ZJhCgyuMe8aymVLwO3Tr6s&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQknSmbEPf0mZwF0XEKeor69VUxQqXSuFaCEdpcG7wsgyqclKYjMwIsw38NvnOW2w44Muc&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-4",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5-ZaOEm5Vu6cgOVCHmUeg-uBLylVUlbeO3t8SqMywoyiHsgu_Hgr5abaLinxDnyNOzyA&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE-nfA9jB57Oz5XpxraurfhidVDSYBfBx6kwe1QHfbpbq80ZJhCgyuMe8aymVLwO3Tr6s&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQknSmbEPf0mZwF0XEKeor69VUxQqXSuFaCEdpcG7wsgyqclKYjMwIsw38NvnOW2w44Muc&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-4",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5-ZaOEm5Vu6cgOVCHmUeg-uBLylVUlbeO3t8SqMywoyiHsgu_Hgr5abaLinxDnyNOzyA&usqp=CAU",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
  ];
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === "ArrowUp") {
        swiperRef?.current?.swiper?.slidePrev();
      } else if (event?.key === "ArrowDown") {
        swiperRef?.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <Swiper
      //   touchStartPreventDefault={false}
      ref={swiperRef}
      direction={"vertical"}
      //   pagination={{
      //     clickable: true,
      //   }}
      //   modules={[Pagination]}
    >
      {CourseCardData?.map((data, index) => (
        <SwiperSlide key={index}>
          <img src={data?.image} alt="" className="h-auto" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ShortsData;

import React from "react";
import { MdOutlineCurrencyRupee } from "react-icons/md";

function DashboardCards() {
  return (
    <div className="flex flex-col justify-between gap-5">
      <div className="flex lg:flex-col gap-5 ">
        <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-full">
          <p className="text-gray-400 font-medium text-sm">Course</p>
          <div className=" flex items-center">
            <p className="text-xl font-bold">10</p>
            <p
              className={`text-purpleColor bg-purpleColor/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
            >
              +2
            </p>
          </div>
        </div>
        <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-full">
          <p className="text-gray-400 font-medium text-sm">Workshops</p>
          <div className=" flex items-center">
            <p className="text-xl font-bold">10</p>
            <p
              className={`text-purpleColor bg-purpleColor/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
            >
              +2
            </p>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-col gap-5 ">
        <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-full">
          <p className="text-gray-400 font-medium text-sm">Join Courses</p>
          <div className=" flex items-center">
            <p className="text-xl font-bold">10</p>
            <p
              className={`text-purpleColor bg-purpleColor/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
            >
              +2
            </p>
          </div>
        </div>
        <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-full">
          <p className="text-gray-400 font-medium text-sm">Total Revenue</p>
          <div className=" flex items-center">
            <p className="text-xl font-bold flex items-center">
              <MdOutlineCurrencyRupee className="" />

              {Number(6522).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p
              className={`text-purpleColor bg-purpleColor/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
            >
              +2
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCards;

import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useGetLectureByLectureIdQuery } from "../../../../redux/ReduxToolkit";
import ReactHlsPlayer from "@ducanh2912/react-hls-player";
import { ShimmerThumbnail } from "react-shimmer-effects";

import no_file from "../../../../assets/pug-dog.png"
import CustomLoader from "../../../Loading/Loading";
import { ApiConstant } from "../../../../Api/ApiConstant";

function CloudVideoPlayer({ setShowVideoPopup, currentLectureData }) {
  const [videoPath, setVideoPath] = useState();
  const [responceStatus, setResponseStatus] = useState();
  const [thubnailUrl, setThubnailUrl] = useState([]);
  const [hideScrollbar, setHideScrollbar] = useState(false);

  const { data: playerData, isLoading: playerDataIsLoading } =
    useGetLectureByLectureIdQuery(currentLectureData?.lectureUid);
  console.log("data ", playerData);

  useEffect(() => {
    setResponseStatus(playerData?.data?.detail?.status);
    const videoUrl =
      playerData?.data?.detail?.outputGroupDetails?.[0]?.streamingUrl;
    setVideoPath(videoUrl);

    const videoFinalUrl = videoUrl?.split("/xper-bucket");
    // setVideoPath(videoFinalUrl?.[1]);
    let urls = [];

    playerData?.data?.detail?.outputGroupDetails?.[2]?.outputDetails?.[0]?.outputFilePaths?.map(
      (images) => {
        try {
          var finalImageArray = images.split("/s1-output-bucket");
          const maxCount = parseInt(finalImageArray?.[1]?.split(".")[1]);
          for (let i = 0; i <= maxCount; i++) {
            var localpath = `${
              finalImageArray?.[1]?.split(".")[0] +
              "." +
              FormatInteger(i, 7) +
              "." +
              finalImageArray?.[1]?.split(".")[2]
            }`;
            urls.push(localpath);
            // console.log("Image Path at ", localpath);
          }
        } catch (e) {
          console.log("Error");
        }
      }
    );
    setThubnailUrl(urls);
  }, [playerData]);
  console.log("videoPath", ApiConstant?.CLOUD_FRONT_URL, videoPath);

  function FormatInteger(num, length) {
    return (num / Math.pow(10, length)).toFixed(length).substr(2);
  }

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-center items-center  fixed top-0 left-0  z-50">
      {playerDataIsLoading && <CustomLoader />}
      <div
        className={`bg-white h-[100vh] lg:h-[70vh]  lg:w-[50%] tablet:w-[60%] px-3 lg:p-5 relative lg:rounded-2xl shadow-lg `}
      >
        <IoCloseSharp
          className={`absolute top-5  lg:top-3 right-5 lg:right-5 text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-90 duration-200 hover:bg-gray-200 rounded-full`}
          onClick={() => setShowVideoPopup(false)}
        />

        {playerData?.data?.detail?.outputGroupDetails?.length === null ||
        playerData?.data?.detail?.outputGroupDetails?.length === undefined ? (
          <div className=" flex flex-col items-center justify-center mt-20 w-screen md:w-auto">
            <img src={no_file} alt="" className={`w-[10rem]`} />
            <p className="text-xl text-gray-600  font-medium text-center mt-3">
              There is no video in this lecture
            </p>
          </div>
        ) : (
          // )  (
          //   <div className="relative ">
          //     <ShimmerThumbnail height={250} rounded className={`w-full mt-10`} />
          //     <p className="">Upload Processing</p>
          //   </div>
          <div className="">
            <div className="">
              {/* <p className="text-xl font-semibold text-gray-700">
                Lecture Preview
              </p> */}
            </div>
            <div className="px-3 my-3 mt-20 lg:mt-10  w-screen md:w-auto ">
              <ReactHlsPlayer
                src={`${ApiConstant?.CLOUD_FRONT_URL}${videoPath}`}
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
                className=" rounded-md lg:rounded-2xl shadow-lg"
              />
            </div>
            <div className="px-3 lg:px-5">
              <p className="text-[#313639] font-semibold text-xl line-clamp-1">
                {currentLectureData?.lectureName}
              </p>
              <p className="mt-1 text-xs lg:line-clamp-3 line-clamp-6 ">
                {currentLectureData?.lectureDescription}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CloudVideoPlayer;

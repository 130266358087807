import React, { useContext, useEffect, useState } from "react";
import LineTextarea from "../../Pages/Shared/LineTextarea/LineTextarea";
import CustomCheckbox from "../../Pages/Shared/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import phone_message from "../../assets/phone_with_message.png";
import CustomInputFile from "../../Pages/Shared/CustomInputFile/CustomInputFile";
import { enqueueSnackbar } from "notistack";
import {
  useGetAllCoursesByInstrutorIdQuery,
  useSendNotificationByCourseIdMutation,
} from "../../redux/ReduxToolkit";
import { AuthContext } from "../../Providers/AuthProvider";
import isJwtTokenExpired, { decode } from "jwt-check-expiry";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import CustomLoader from "../Loading/Loading";
import WhatsAppFormComponent from "./WhatsAppFormComponent";
import ReceiverChatBubble from "./ReceiverChatBubble";

function WhatsAppCreateNotification() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("select course");
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const { accessToken, userData } = useContext(AuthContext);
  const [selectCourseId, setSelectCourseId] = useState(null);

  //   console.log("educator id = ", userData?.userInfo?._id);

  const { data: allCourse, isLoading: allCourseIsLoading } =
    useGetAllCoursesByInstrutorIdQuery({
      id: userData?.userInfo?._id,
      token: accessToken,
    });

  const [
    sendNotificationByCourseId,
    { isLoading: sendNotificationByCourseIdIsLoading },
  ] = useSendNotificationByCourseIdMutation();

  useEffect(() => {
    if (selectCourseId === null) {
      setDropdownValue("select course");
    }
  });

  const handleFormSubmit = async () => {
    if (!selectCourseId) {
      enqueueSnackbar(" Please select course !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (title === null || title === undefined || title.length === 0) {
      enqueueSnackbar(" Title is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      description === null ||
      description === undefined ||
      description.length === 0
    ) {
      enqueueSnackbar(" Description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    // console.log("title", title);
    // console.log("description", description);
    // console.log("image ", image);
    // console.log("courseId ", selectCourseId);

    try {
      const response = await sendNotificationByCourseId({
        body: {
          title: title,
          body: description,
          image: image,
          courseId: selectCourseId,
        },
        token: accessToken,
      });

      if (response?.data) {
        // console.log("api response = ", response?.data?.msg);
        enqueueSnackbar(response?.data?.msg, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setTitle("");
        setDescription("");
        setShowImageUploader(false);
        setSelectCourseId(null);
      } else {
        // console.log(response?.error?.data?.msg);
        enqueueSnackbar(response?.error?.data?.msg, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setTitle("");
        setDescription("");
        setShowImageUploader(false);
        setSelectCourseId(null);
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const handleUploadFile = async (e) => {
    console.log("file path", e.target.files[0]?.name);

    // let formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // formData.append("userId", "test-user-id");

    setImage(e.target.files[0]);

    // console.log("Formdata", formData.entries());

    // try {
    // 	const uploadThumbnailResponse = await uploadCourseThumbnail({
    // 		body: formData,
    // 	});

    // 	setUploadThumbnailResponse(uploadThumbnailResponse?.data);
    // 	// console.log("uploadThumbnailResponse", uploadThumbnailResponse?.data);
    // } catch (error) {
    // 	console.error("Mutation failed:", error.message);
    // }
  };

  const [message] = useState({
    text: `📢 Exciting Announcement from Quest4Physics!\n\nWe are thrilled to introduce our new IIT-JEE preparation course designed to help you ace the exam with expert guidance, in-depth study material, and personalized mentorship. 🚀\n\nLimited seats available! Enroll now to secure your spot and kickstart your journey to IIT!\n\nFor more details, reply to this message or visit our website.\n\nBest regards,\nQuest4Physics Team\nYour path to success starts here! 🌟`,
  });
  return (
    <div className="flex flex-col lg:flex-row justify-center gap-5 items-start shadow-no-border-shadow mb-5 rounded-2xl lg:p-10 ">
      {(allCourseIsLoading || sendNotificationByCourseIdIsLoading) && (
        <CustomLoader />
      )}
      <div className="w-1/2">
        <WhatsAppFormComponent />
      </div>
      <div className="w-1/2 ">
        <div className="flex-grow p-4 overflow-y-auto">
          <ReceiverChatBubble message={message.text} />
        </div>
      </div>
    </div>
  );
}

export default WhatsAppCreateNotification;

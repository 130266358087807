import FallbackRouter from "../router/FallbackRouter";
import AffiliateRouter from "../router/affiliate-router";
import { EducatorRouter } from "../router/educator-router";

export const getRouter = () => {
  const domain = window.location.hostname;
  const subDomain = getSubdomain(domain);
  console.log("Subdomain", subDomain);
  const defaultApp = AppRouterList.find((app) => app.default);
  if (!defaultApp) throw new Error("Required Main Router");

  if (subDomain === "" || subDomain === "www" || subDomain === "192168")
    return defaultApp.router;

  const app = AppRouterList.find((app) => app.subdomain === subDomain);
  if (!app) {
    // if (!app) throw new Error("No App Detected");
    const { router } = AppRouterList.find(
      (app) => app.subdomain === "fallback"
    );
    return router;
  }
  return app.router;
};

const getSubdomain = (domain) => {
  const domSplit = domain.split(".");
  let sliceTill = -2;
  var isLocalHost = domSplit.slice(-1)[0] === "localhost";
  if (isLocalHost) sliceTill = -1;
  return domSplit.slice(0, sliceTill).join("");
};

// export const getRouter = (data) => {
//   const defaultApp = AppThemes.find((theme) => theme.default);
//   if (!defaultApp) throw new Error("Required Main Router");

//   if (data.themeId === undefined || data.themeId === null)
//     return defaultApp.router;

//   const app = AppThemes.find((app) => app.themeId === data.themeId);

//   if (!app) {
//     const { router } = AppRoutes.find((app) => app.subdomain === "fallback");
//     return router;
//   }
//   return app.router;
// };

export const AppRouterList = [
  {
    subdomain: "affiliate",
    router: AffiliateRouter,
    default: false,
  },
  {
    subdomain: "admin",
    router: EducatorRouter,
    default: true,
  },
  {
    subdomain: "fallback",
    router: FallbackRouter,
    default: false,
  },
];

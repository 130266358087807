import React, { useState, useEffect } from "react";

// Modal component for solving the problem
function SolutionModal({ isOpen, onClose, doubt }) {
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);

  // Disable scroll on modal open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Restore scroll
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleImageUpload = (e) => {
    setImageFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleVideoUpload = (e) => {
    setVideoFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    // Logic to submit the solution with image/video (if needed)
    alert("Solution submitted!");
    onClose(); // Close modal after submitting
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-lg w-[90%] max-w-lg shadow-lg relative">
        <h2 className="text-xl font-bold mb-4">Solve Doubt</h2>

        {/* Student Name and Question */}
        <p className="text-sm font-semibold text-blue-600">{doubt.name}</p>
        <p className="text-lg font-bold text-gray-800">{doubt.question}</p>

        {/* Input for the solution */}
        <textarea
          rows="4"
          className="w-full mt-3 p-2 border rounded-md"
          placeholder="Type your solution here..."
        ></textarea>

        {/* Upload Image */}
        <div className="mt-3">
          <label className="block mb-1">Upload Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="border p-2 w-full rounded-md"
          />
          {imageFile && (
            <div className="mt-2">
              <img
                src={imageFile}
                alt="Uploaded"
                className="max-w-full h-auto rounded-md"
              />
            </div>
          )}
        </div>

        {/* Upload Video */}
        <div className="mt-3">
          <label className="block mb-1">Upload Video:</label>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            className="border p-2 w-full rounded-md"
          />
          {videoFile && (
            <div className="mt-2">
              <video controls className="max-w-full h-auto rounded-md">
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>

        {/* Submit and Close buttons */}
        <div className="flex justify-between mt-4">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            onClick={handleSubmit}
          >
            Submit Solution
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function DoubtComponent() {
  // Generate an array of 20 doubts
  const [studentDoubts, setStudentDoubts] = useState(
    Array.from({ length: 20 }, (_, index) => ({
      name: `Student ${index + 1}`,
      question: `Question ${index + 1}`,
      description: `Description of doubt for question ${index + 1}.`,
    }))
  );

  const [selectedDoubt, setSelectedDoubt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle opening the modal
  const openModal = (doubt) => {
    setSelectedDoubt(doubt);
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDoubt(null); // Clear selected doubt
  };

  return (
    <div className="w-full p-5 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-700 text-center md:text-left">
        Doubts
      </h1>
      {/* Show total doubt count */}
      <p className="mt-2 text-xl text-red-500 text-center md:text-left">
        Total Doubts: {studentDoubts.length}
      </p>

      {/* List of doubts with overflow handling */}
      <div className="mt-5 max-h-[75vh] overflow-y-auto space-y-4">
        {studentDoubts.map((doubt, index) => (
          <div
            key={index}
            className="p-4 bg-white rounded-lg shadow-md flex flex-col md:flex-row justify-between items-start md:items-center gap-4"
          >
            <div>
              {/* Student Name */}
              <p className="text-sm font-semibold text-blue-600">
                {doubt.name}
              </p>

              {/* Question Title */}
              <p className="text-lg font-bold text-gray-800">
                {doubt.question}
              </p>

              {/* Doubt Description */}
              <p className="text-gray-600 mt-1">{doubt.description}</p>
            </div>

            {/* Solution Button */}
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
              onClick={() => openModal(doubt)}
            >
              Solution
            </button>
          </div>
        ))}
      </div>

      {/* Solution Modal */}
      <SolutionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        doubt={selectedDoubt}
      />
    </div>
  );
}

export default DoubtComponent;

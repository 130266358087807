import React, { useState } from "react";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import { FaRupeeSign } from "react-icons/fa";

function AffilateCourseCard({ arrayIndex, courseData }) {
  const handleCardClick = () => {
    // console.log("card click");
    console.log(courseData?.courseThumbnail?.[0]);
  };
  return (
    <div
      className="shadow-xl rounded-lg flex flex-col gap-3  w-[20rem] h-[23rem] cursor-pointer mx-2.5  mb-9 relative group"
      onClick={() => handleCardClick()}
    >
      <div className="overflow-hidden inline-block rounded-t-lg h-[10rem]">
        <img
          src={`https://axg6mefkv4.execute-api.ap-south-1.amazonaws.com/aws/serverless/optimize?url=https://ddwett0ep4121.cloudfront.net/${courseData?.courseThumbnail?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1`}
          alt=""
          className={`rounded-t-lg duration-200  group-hover:scale-110`}
        />
      </div>
      <div className="">
        <div className="flex flex-col p-3">
          <p className=" font-medium leading-tight text-sm line-clamp-2">
            {courseData?.courseName}
          </p>
          <p className="text-xs text-gray-400 mt-2 line-clamp-3">
            {courseData?.courseDescription}
          </p>
        </div>
        <div className="flex  items-center justify-between absolute bottom-0 w-full p-3 ">
          <div className="">
            <CustomButton
              lable={`Create share link`}
              className={` !mx-0 !rounded-full !text-xs bg-[#5966F1] hover:bg-[#404aba] text-white border-none `}
            />
          </div>
          <div className="flex items-center  gap-1 font-semibold p-3 pb-0 ">
            {courseData?.coursePricing?.[0]?.isDiscounted === true ? (
              <s className="flex items-center text-xs text-gray-400">
                <span className="">
                  <FaRupeeSign />
                </span>
                {courseData?.coursePricing?.[0]?.priceTag}
              </s>
            ) : (
              <b className="flex items-center">
                <span className="">
                  <FaRupeeSign />
                </span>
                {courseData?.coursePricing?.[0]?.priceTag}
              </b>
            )}
            {courseData?.coursePricing?.[0]?.isDiscounted === true && (
              <b className="flex items-center ">
                <span className="">
                  <FaRupeeSign />
                </span>
                {Math.round(
                  courseData?.coursePricing?.[0]?.priceTag -
                    courseData?.coursePricing?.[0]?.priceTag *
                      (courseData?.coursePricing?.[0]?.discountRate / 100)
                )}
              </b>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AffilateCourseCard;

import React, { useContext, useEffect, useRef, useState } from "react";
import CustomInput from "../../../Pages/Shared/CustomInput/CustomInput";
import CustomInputFile from "../../../Pages/Shared/CustomInputFile/CustomInputFile";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import CustomTextarea from "../../../Pages/Shared/CustomTextarea/CustomTextarea";
import { IoCloseOutline } from "react-icons/io5";
import CoursePricePopup from "./CoursePricePopup";
import {
  useCreateCourseMutation,
  useGetAllGoalQuery,
  useGetCoursesByCourseIdQuery,
  useUploadCourseThumbnailMutation,
} from "../../../redux/ReduxToolkit";
import SelectLanguage from "./SelectLanguage";
import CourseType from "./CourseType";
import CourseCoupon from "./CourseCoupon";
import Goals from "./Goals";
import SubGoal from "./SubGoal";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../Providers/AuthProvider";
import CustomLoader from "../../Loading/Loading";
import { IoIosArrowRoundBack } from "react-icons/io";

import imagePath from "../../../assets/web-logo.webp";

function CreateCoursePopup({
  setActiveStep,
  setNewCourseId,
  courseRefetch,
  courseId,
}) {
  const menuRef = useRef();
  const { userData } = useContext(AuthContext);
  const [inputTag, setInputTag] = useState("");
  const [addChips, setAddChips] = useState([]);
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [showAddChipsButton, setShowAddChipsButton] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [priceList, setPriceList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [currentSubGoalList, setCurrentSubGoalList] = useState();
  const [currentSubGoalId, setCurrentSubGoalId] = useState(null);
  const [languageList, setLanguageList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [uploadThumbnailResponse, setUploadThumbnailResponse] = useState();
  const [imageUploadingIsLoading, setImageUploadingIsLoading] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const { data: allGoal } = useGetAllGoalQuery();
  const [createCourse, error] = useCreateCourseMutation();
  const [uploadCourseThumbnail] = useUploadCourseThumbnailMutation();

  const {
    data: getCourseDataByCourseId,
    isLoading: getCourseDataByCourseIdIsLoading,
  } = useGetCoursesByCourseIdQuery(courseId, {
    skip: !courseId || courseId === "",
  });

  const handleInputTag = (e) => {
    setInputTag(e.target.value);
    setShowAddChipsButton(true);
  };

  const handleAddChips = () => {
    if (inputTag.trim() !== "") {
      setAddChips([...addChips, inputTag.trim()]);
      setInputTag("");
      setShowAddChipsButton(false);
    }
  };

  const handleRemoveChips = (index) => {
    const newChips = addChips.filter((_, idx) => idx !== index);
    setAddChips(newChips);
  };

  const handleFormSave = async () => {
    if (
      courseTitle === null ||
      courseTitle === undefined ||
      courseTitle.length === 0
    ) {
      enqueueSnackbar("Course Title is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (
      courseDescription === null ||
      courseDescription === undefined ||
      courseDescription.length === 0
    ) {
      enqueueSnackbar("Course Description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (currentSubGoalList?.goalTitle === undefined) {
      enqueueSnackbar("Please Select Goal !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (currentSubGoalId === null || currentSubGoalId === undefined) {
      enqueueSnackbar("Please Select  Subgoal !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (languageList.length === 0) {
      enqueueSnackbar("Please Select  Language !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (courseTypeList.length === 0) {
      enqueueSnackbar("Please Select  Course Type !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (courseTypeList.length === 0) {
      enqueueSnackbar("Please Select  Course Type !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }

    console.log("uploadThumbnailResponse", uploadThumbnailResponse);
    const thumbanailData = {
      url: uploadThumbnailResponse?.url,
      resourceId: uploadThumbnailResponse?.path,
      summary: uploadThumbnailResponse?.previewLink,
    };
    const fakeThumbanailData = {
      url: "https://api.quest4physics.com/service/utility/resizeImage?url=https://d2thm7494bvft.cloudfront.net//uploads/course/data/test-user-id/62471f31-6c31-45a2-89bc-caee6b6a6586/bf7e50ee-e8c7-418d-8e70-f06ca8524fec.jfif?auto=compress&cs=tinysrgb&dpr=1&width=300&height=200&quality=80",
      resourceId:
        "/uploads/course/data/test-user-id/62471f31-6c31-45a2-89bc-caee6b6a6586/bf7e50ee-e8c7-418d-8e70-f06ca8524fec.jfif",
      summary:
        "https://api.quest4physics.com/service/utility/resizeImage?url=https://d2thm7494bvft.cloudfront.net//uploads/course/data/test-user-id/62471f31-6c31-45a2-89bc-caee6b6a6586/bf7e50ee-e8c7-418d-8e70-f06ca8524fec.jfif?auto=compress&cs=tinysrgb&dpr=1&width=300&height=200&quality=80",
    };

    // if (!thumbanailData?.url) {
    //   enqueueSnackbar("Thumbanail is required !", {
    //     autoHideDuration: 3000,
    //     variant: "error",
    //     anchorOrigin: { horizontal: "right", vertical: "top" },
    //   });
    //   return;
    // }

    // console.log("data aa gya = ", {
    //   courseName: courseTitle,
    //   courseDescription: courseDescription,
    //   coursePricing: priceList,
    //   couponsEligible: couponList,
    //   subgoalId: [currentSubGoalId],
    //   goalId: [currentSubGoalList?.goalTitle],
    //   language: [languageList],
    //   courseType: [courseTypeList],
    //   courseThumbnail: [thumbanailData],
    //   courseUid: uploadThumbnailResponse?.uid,
    //   educatorInfo: [userData?.userInfo?._id],
    // });
    try {
      const response = await createCourse({
        body: {
          courseName: courseTitle,
          courseDescription: courseDescription,
          coursePricing: priceList,
          couponsEligible: couponList,
          subgoalId: [currentSubGoalId],
          goalId: [currentSubGoalList?._id],
          language: [languageList],
          courseType: [courseTypeList],
          courseThumbnail: [fakeThumbanailData],
          courseUid: uploadThumbnailResponse?.uid,
          educatorInfo: [userData?.userInfo?._id],
        },
      });

      // console.log(response);

      if (createCourse) {
        console.log("Course created", response?.data?.id);
        setNewCourseId(response?.data?.id);
        courseRefetch();
        setActiveStep(null);
      } else {
        console.log("Oops! Course is not created");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  const handleRemovePriceChips = (index) => {
    const newChips = priceList.filter((_, idx) => idx !== index);
    setPriceList(newChips);
  };
  const handleRemoveCouponChips = (index) => {
    const newChips = couponList.filter((_, idx) => idx !== index);
    setCouponList(newChips);
  };

  // const handleFileUpload = async (e) => {
  //   e.preventDefault();

  //   let formData = new FormData();
  //   // formData.append("file", imagePath);
  //   formData.append("file", e?.target?.files[0]);
  //   formData.append("userId", "test-user-id");

  //   // console.log("Formdata", formData.entries());
  //   setImageUploadingIsLoading(true);
  //   try {
  //     const uploadThumbnailResponse = await uploadCourseThumbnail({
  //       body: formData,
  //     });

  //     if (uploadThumbnailResponse?.data) {
  //       enqueueSnackbar(uploadThumbnailResponse?.data?.message, {
  //         autoHideDuration: 3000,
  //         variant: "success",
  //         anchorOrigin: { horizontal: "right", vertical: "top" },
  //       });
  //       setUploadThumbnailResponse(uploadThumbnailResponse?.data);
  //       setImageUploadingIsLoading(false);
  //     } else {
  //       // console.log("Oops! Image is not uploaded. ");
  //       enqueueSnackbar("Oops! Image is not uploaded. ", {
  //         autoHideDuration: 3000,
  //         variant: "error",
  //         anchorOrigin: { horizontal: "right", vertical: "top" },
  //       });
  //       setImageUploadingIsLoading(false);
  //     }
  //     setImageUploadingIsLoading(false);
  //   } catch (error) {
  //     console.error("Mutation failed:", error.message);
  //     setImageUploadingIsLoading(false);
  //   }
  // };

  //   useEffect(()=>{console.log("image ka response ", uploadThumbnailResponse?.previewLink);})

  useEffect(() => {
    if (getCourseDataByCourseId) {
      setCourseTitle(getCourseDataByCourseId?.course?.courseName);
      setCourseDescription(getCourseDataByCourseId?.course?.courseDescription);
    }
  }, [getCourseDataByCourseId]);

  // console.log("courseId", getCourseDataByCourseId?.course);

  // useEffect(() => {
  //   if (allGoal) {
  //     handleFileUpload();
  //   }
  // }, [allGoal]);

  return (
    <div className=" py-20  w-full text-[#484949] h-[100vh] lg:h-[100vh] overflow-y-auto no-scrollbar ">
      {(getCourseDataByCourseIdIsLoading || imageUploadingIsLoading) && (
        <CustomLoader />
      )}
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col lg:w-[60%] items-start  rounded-md  w-[90%] py-10 ">
          <div className="lg:hidden">
            <IoIosArrowRoundBack
              className="text-4xl border rounded-lg mb-4"
              onClick={() => setActiveStep(null)}
            />
          </div>
          <div className="text-3xl  flex justify-end font-bold  ">
            <p className="text-end text-[#484949]">Create Course</p>
          </div>

          <div className="mt-4 w-full relative">
            <CustomInput
              inputLable={`Course Title `}
              onChange={(e) => {
                setCourseTitle(e.target.value);
              }}
              value={courseTitle}
            />

            <CustomTextarea
              inputLable={`Course Description`}
              onChange={(e) => {
                setCourseDescription(e.target.value);
              }}
              value={courseDescription}
            />

            {/* <div className=" flex flex-row flex-wrap  w-[100%]">
              {addChips.map((chip, index) => (
                <div
                  key={index}
                  className="bg-[#DFDFDF] text-gray-800 ps-3 pe-2 py-1 rounded-xl  cursor-pointer me-2 mt-2 flex items-center"
                >
                  {chip}{" "}
                  <IoCloseOutline
                    className={`ms-5 text-xl text-white rounded-full bg-[#A7A7A7]`}
                    onClick={() => handleRemoveChips(index)}
                  />
                </div>
              ))}
            </div>

            <div className="flex items-center relative  ">
              <CustomInput
                inputLable={`Add Tag`}
                value={inputTag}
                onChange={(e) => handleInputTag(e)}
              />
              {showAddChipsButton && (
                <CustomButton
                  lable={`Add Chips`}
                  className={`w-[10rem]`}
                  onClick={handleAddChips}
                />
              )}
            </div> */}

            <div className="">
              <Goals
                allGoal={allGoal}
                setCurrentSubGoalList={setCurrentSubGoalList}
              />
            </div>
            <div className="">
              <SubGoal
                currentSubGoalList={currentSubGoalList}
                setCurrentSubGoalId={setCurrentSubGoalId}
              />
            </div>

            <div className="my-5">
              <SelectLanguage setLanguageList={setLanguageList} />
            </div>

            <div className="my-5">
              <CourseType setCourseTypeList={setCourseTypeList} />
            </div>

            {priceList?.length > 0 && (
              <div className="flex flex-col">
                <p className="">Price Tag</p>
                <div className=" flex flex-row ">
                  {priceList?.map((chips, index) => (
                    <div
                      className={`bg-[#DFDFDF] text-gray-800 ps-3 px-3 py-2 rounded-xl  cursor-pointer me-2 mt-2 flex items-center relative min-w-[100px] ${
                        chips?.isDiscounted ? "bg-indigo-500 text-white" : ""
                      }  ${chips?.isTrail ? "bg-red-500 text-white" : ""}`}
                      key={index}
                    >
                      <div className="">
                        <div className="mr-10 mb-2 text-sm font-thin">
                          {chips?.packName}
                        </div>
                        <div className="flex items-center justify-between w-full">
                          {chips.isTrail && (
                            <div className="">
                              ₹
                              {parseInt(chips?.priceTag) -
                                (parseInt(chips?.priceTag) *
                                  parseInt(chips?.discountRate)) /
                                  100}
                            </div>
                          )}

                          {!chips.isTrail && (
                            <div className="flex items-center justify-between">
                              <div className="">
                                ₹
                                {parseInt(chips?.priceTag) -
                                  (parseInt(chips?.priceTag) *
                                    parseInt(chips?.discountRate)) /
                                    100}
                              </div>
                              <s className="text-xs font-thin ms-3 text-gray-200">
                                {!chips?.isTrail
                                  ? "₹" + chips?.priceTag
                                  : "₹" + chips?.priceTag + " (Trail)"}
                              </s>
                            </div>
                          )}
                        </div>
                      </div>
                      <IoCloseOutline
                        className={`ms-5 text-white rounded-full bg-[#A7A7A7] absolute top-2 right-2 cursor-pointer `}
                        onClick={() => handleRemovePriceChips(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {couponList.length > 0 && (
              <div className="flex flex-col mt-3">
                <p className="">Coupon Tag</p>
                <div className="flex relative">
                  {couponList?.map((couponChips, index) => (
                    <div
                      key={index}
                      className={`bg-[#DFDFDF] text-gray-800 ps-3 px-3 py-2 rounded-xl  cursor-pointer me-2 mt-2 flex items-center relative min-w-[150px] ${
                        couponChips?.singleUse
                          ? " bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white"
                          : ""
                      }`}
                    >
                      <div className="flex flex-col">
                        <p className="font-thin "> {couponChips?.couponName}</p>
                        <p className="text-xs">
                          {couponChips?.couponRate + "% Off"}
                        </p>
                      </div>
                      <IoCloseOutline
                        className={`ms-5 text-white rounded-full bg-[#A7A7A7] absolute top-2 right-2 cursor-pointer `}
                        onClick={() => handleRemoveCouponChips(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* <div className="flex w-full mt-12">
              <CustomInputFile
                lable={`Upload Thumbnail`}
                onChange={(e) => handleFileUpload(e)}
                previewLink={uploadThumbnailResponse?.previewLink}
              />
            </div> */}

            <div className="flex justify-end flex-col items-end">
              <CustomButton
                lable={`Add Pricing`}
                className={`mt-3 mx-0 duration-200 bg-[#121212] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
                onClick={() => setShowPricePopup(true)}
              />
              <CustomButton
                lable={`Add Coupon`}
                className={`mt-3 mx-0 duration-200 bg-[#121212] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
                onClick={() => setShowCouponPopup(true)}
              />

              <CustomButton
                lable={`Save`}
                className={`mt-3 mx-0 duration-200 bg-[#49982E] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
                onClick={() => handleFormSave()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed top-0 left-0 z-50">
        {showPricePopup && (
          <CoursePricePopup
            zoomInPopup={`animate-zoomInPopup`}
            setShowPricePopup={setShowPricePopup}
            setPriceList={setPriceList}
            priceList={priceList}
          />
        )}
      </div>
      <div className="fixed top-0 left-0 z-50">
        {showCouponPopup && (
          <CourseCoupon
            zoomInPopup={`animate-zoomInPopup`}
            setShowCouponPopup={setShowCouponPopup}
            setCouponList={setCouponList}
            couponList={couponList}
          />
        )}
      </div>
    </div>
  );
}

export default CreateCoursePopup;

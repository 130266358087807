import React, { useContext, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import BrandLogo from "../../../Pages/Shared/BrandLogo/BrandLogo";
import { BiSupport } from "react-icons/bi";
import { TbAffiliateFilled } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import { SiYoutubeshorts } from "react-icons/si";
// import images
import { RxHamburgerMenu } from "react-icons/rx";
import dashboard_image from "../../../assets/ViewGrid.svg";
import class_image from "../../../assets/UserGroupOutline.svg";
import batch_image from "../../../assets/UsersOutline.svg";
import enrollment_image from "../../../assets/ClipboardListOutline.svg";
import course_image from "../../../assets/off.svg";
import fileStorage_image from "../../../assets/fileStorage_image.svg";
import notification_image from "../../../assets/BellOutline.svg";

import { FaBook, FaMonero, FaRegBell, FaUsers } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { HiOutlineClipboardList } from "react-icons/hi";
import {
  MdDashboard,
  MdOutlineDashboard,
  MdOutlineSdStorage,
} from "react-icons/md";

// import images

import { AuthContext } from "../../../Providers/AuthProvider";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { IoSearchSharp, IoSettingsOutline } from "react-icons/io5";
import { RiAccountPinCircleLine } from "react-icons/ri";
import logo from "../../../assets/logo.avif";

import { AiOutlineMenuFold } from "react-icons/ai";
import { BsMenuApp } from "react-icons/bs";

function MobileMenuSidebar() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const location = useLocation();
  const [showMenuBar, setShowMenuBar] = useState(false);

  const dashboardMenu = [
    {
      title: "Dashboard",
      link: "/",
      img: dashboard_image,
      icon: <MdDashboard />,
    },

    // {
    // 	title: "Classes",
    // 	link: "/",
    // 	img: class_image,
    // },
    {
      title: "Courses",
      link: "/add-course",
      img: course_image,
      icon: <FaBook />,
    },
    // {
    //   title: "Test Series",
    //   link: "/test-series",
    //   img: class_image,
    //   icon: <FaUsers className="text-2xl" />,
    // },
    // {
    // 	title: "Batch",
    // 	link: "#",
    // 	img: batch_image,
    // },
    // {
    //   title: "Live Class",
    //   link: "/live-class",
    //   img: batch_image,
    //   icon: <FaUserFriends className="text-2xl" />,
    // },
    // {
    //   title: "Enrollment",
    //   link: "/enrollment",
    //   img: enrollment_image,
    //   icon: <HiOutlineClipboardList className="text-2xl" />,
    // },
    // {
    // 	title: "Reports",
    // 	link: "#",
    // 	img: report_image,
    // },
    {
      title: "Cloud Storage",
      link: "/cloud-storage",
      img: fileStorage_image,
      icon: <MdOutlineSdStorage />,
    },
    {
      title: "Notification",
      link: "/notification",
      img: notification_image,
      icon: <FaRegBell />,
    },
    {
      title: "Shorts",
      link: "/shorts",
      img: notification_image,
      icon: <SiYoutubeshorts />,
    },
    // {
    //   title: "Support",
    //   link: "/support",
    //   icon: <BiSupport />,
    //   img: batch_image,
    // },
    // {
    //   title: "Account",
    //   link: "/account",
    //   icon: <RiAccountPinCircleLine />,
    //   img: batch_image,
    // },
    // {
    //   title: "Payout",
    //   link: "/payout",
    //   icon: <FaMonero />,
    //   img: batch_image,
    // },
    // {
    //   title: "Affiliate",
    //   link: "#",
    //   icon: <TbAffiliateFilled />,
    //   img: batch_image,
    // },
  ];

  return (
    <>
      {/* {!showMenuBar && ( */}
      <div className=" w-full  flex items-center justify-between p-2 z-50 bg-white ">
        {/* <img
          src={dashboard_image}
          alt=""
          className={`bg-[#227c43] p-2 rounded-md  duration-200  ${
            showMenuBar ? "rotate-360" : ""
          }`}
          onClick={() => setShowMenuBar(true)}
        /> */}
        <RxHamburgerMenu
          onClick={() => setShowMenuBar(true)}
          className="text-3xl ms-3"
        />
        <div className="flex justify-center  ">
          <CustomButton
            lable={`Logout`}
            className={`hidden text-white border-none bg-purpleColor !rounded-full py-3 !px-10 !font-normal !mx-0 `}
            onClick={() => logout()}
          />
        </div>
        {/* <div className="flex items-center bg-white p-2 rounded-lg w-full ms-5 text-sm">
          <IoSearchSharp />
          <input
            type="text"
            className={` bg-transparent px-2 focus:outline-none w-full`}
            placeholder="Search Course"
          />
        </div> */}
      </div>
      {/* )} */}
      {showMenuBar && (
        <div
          className={` z-50 w-[20rem] min-h-full tablet:w-[20%] bg-primaryColor py-8 lg:relative h-[100vh] overflow-y-auto no-scrollbar shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]  absolute duration-500  ${
            showMenuBar ? "left-0 top-0" : "-left-[500px]"
          }`}
        >
          <div className="flex items-center  tablet:px-1">
            {/* <BrandLogo className={`!w-[2rem]  tablet:-translate-y-0`} />
        <p className="text-white  text-xl tablet:pt-2">DexLabz</p> */}
            <img src={logo} alt="" className="w-[15rem] ms-3  invert" />
          </div>
          <IoIosArrowBack
            onClick={() => setShowMenuBar(false)}
            className={`text-white text-2xl absolute top-5 right-5`}
          />

          <ul className="flex flex-col text-left text-white mt-10">
            {dashboardMenu.map((item, index) => (
              <li
                className={`flex w-full   mt-1  text-base ${
                  location.pathname === item?.link
                    ? "border-l-4 border-white"
                    : " border-l-4 border-transparent"
                }`}
                key={index}
              >
                <p
                  to={item.link}
                  onClick={() => {
                    navigate(item.link);
                    setShowMenuBar(false);
                  }}
                  className={`flex items-center  h-full w-full px-6 py-3 hover:cursor-pointer hover:bg-gradient-to-r   tablet:px-1 tablet:text-sm text-white `}
                >
                  {/* <img src={item.img} alt="" className="me-2" /> */}
                  <span className="me-3 text-xl">{item?.icon}</span>{" "}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
          {/* <div className=" text-white mt-4">
        <p className="px-6 py-4 ">TEAMS</p>
        <ul className="">
          <li className={`flex w-full   mt-1  text-base bg-green-0 `}>
            <p
              className={`flex items-center  h-full w-full px-6 py-4 hover:cursor-pointer hover:bg-gradient-to-r hover:from-[#1C4185] hover:to-[#0f3c8f] tablet:px-1 tablet:text-sm`}
            >
              {" "}
              <img src={message_image} alt="" className="me-2" />
              Messages
            </p>
          </li>
          <li className={`flex w-full   mt-1  text-base bg-green-0 `}>
            <p
              className={`flex items-center  h-full w-full px-6 py-4 hover:cursor-pointer hover:bg-gradient-to-r hover:from-[#1C4185] hover:to-[#0f3c8f] tablet:px-1 tablet:text-sm`}
            >
              {" "}
              <img src={call_image} alt="" className="me-2" />
              Call Meetting
            </p>
          </li>
        </ul>
      </div> */}

          <div className=" absolute bottom-3 w-full flex flex-col items-center gap-3">
            {/* <div className="flex justify-center  ">
              <CustomButton
                lable={`Logout`}
                className={`bg-white border-none !rounded-full py-3 !px-10 !font-normal !mx-0`}
                onClick={() => logout()}
              />
            </div> */}

            <p
              onClick={() => navigate("https://www.phynlabz.com/")}
              className="text-white font-normal text-xs lg:bottom-10 w-full text-center cursor-pointer "
            >
              Powered by &copy;PhynLabz Tech <br />
              <span className="text-gray-300 mt-2 text-xsm">
                Version 1.0.0 alpha
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileMenuSidebar;

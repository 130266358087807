import { Outlet } from "react-router-dom";

export default function ContentAriea() {
  return (
    <div className="w-full h-auto bg-white relative no-scrollbar overflow-hidden  ">
      <div className="w-full  overflow-hidden no-scrollbar   ">
        <Outlet />
      </div>
    </div>
  );
}

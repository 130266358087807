import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { Utils } from "../../../utils/utils";
import parse from "html-react-parser";

function PreviewQuestion({
  setPreviewQuestion,
  questionData,
  answerData,
  optionsArray,
  rightPopup,
}) {
  console.log("options = ", optionsArray);
  return (
    <div className="w-full h-screen bg-[rgba(128,128,128,.8)] flex flex-col justify-center items-center fixed top-0 left-0 z-50">
      <div
        className={`p-10 w-[70%] lg:w-[50%] 2xl:w-[35%] 3xl:w-[30%] tablet:w-[70%]  bg-white   min-h-full  absolute right-0 top-0  transition-all duration-700 ${rightPopup}`}
      >
        <MdOutlineClose
          onClick={() => setPreviewQuestion(false)}
          className="absolute right-5 top-5 text-3xl hover:rotate-45 duration-300 cursor-pointer"
        />

        <div className="flex flex-col">
          <div className="flex items-center mb-3">
            <span className="font-medium text-lg">{parse(questionData)}</span>
          </div>
          {optionsArray?.map((options, index) => (
            <div className="ms-5 flex items-start my-1" key={index}>
              <span className="me-3">{index + 1}.</span>
              {options?.selected === true ? (
                <div className="flex items-center justify-start w-full">
                  <span className="text-green-600 font-medium" key={index}>
                    {parse(options?.content)}
                  </span>
                  <img
                    src={require("../../../assets/right-tik.png")}
                    alt=""
                    className="w-[2rem] ms-5"
                  />
                </div>
              ) : (
                <span className="" key={index}>
                  {parse(options?.content)}
                </span>
              )}
            </div>
          ))}

          <div className="ms-5 mt-2  ">
            <span className=" font-medium text-[#313639]  ">
              Answer Description
            </span>
            <span className="text-gray-500 text-sm mt-2 capitalize">
              {parse(answerData)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewQuestion;

/* eslint-disable no-unused-vars */
import { useContext, useState } from "react";
import { useGetAllLectureByChapterIdQuery } from "../../../redux/ReduxToolkit";
import CustomLoader from "../../Loading/Loading";
import CourseCard from "../AddCourse/CourseCrad";
import AddLectureForm from "./AddLecture";
import LectureCard from "./LectureCard";

import no_data_image from "../../../assets/no_data(course_component).png";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { AuthContext } from "../../../Providers/AuthProvider";

const ManageLecture = ({
  setActiveStep,
  chapterId,
  currentSectionId,
  courseId,
  setCurrentLectureId,
}) => {
  const { accessToken } = useContext(AuthContext);

  const [showLecturePreviewBox, setShowLecturePreviewBox] = useState(false);
  const [currentLectureUid, setCurrentLectureUid] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [currentLectureAllData, setCurrentLectureAllData] = useState();
  const [lectureAllData, setLectureAllData] = useState({
    lectureName: "",
    lectureDescription: "",
    lectureUid: "",
  });

  const {
    data,
    isLoading,
    refetch: refetchAllLectureByChapter,
  } = useGetAllLectureByChapterIdQuery({
    id: chapterId,
    token: accessToken,
  });

  const handleClickLectureCard = (lecture) => {
    setLectureAllData({
      lectureName: lecture?.lectureName,
      lectureDescription: lecture?.lectureDescription,
      lectureUid: lecture?.lectureUid,
    });
    // setShowLecturePreviewBox(true);
    setCurrentLectureId(lecture?.lectureUid);
    setCurrentLectureUid(lecture?.lectureUid);
    setCurrentLectureAllData(lecture);
  };

  const handleUploadVideo = () => {
    // console.log("Upload Video");
  };

  // console.log("lectuer me course ki id = ",courseId)
  // console.log("lectuer me section ki id = ",currentSectionId)
  // console.log("lectuer me chapter ki id = ",chapterId)
  // console.log("lectuer me current lecture ka data  = ", lectureAllData);

  const handleAddLecture = () => {
    setLectureAllData({
      lectureName: "",
      lectureDescription: "",
      lectureUid: "",
    });
  };

  return (
    <div className="flex flex-col   w-full  h-full  ">
      {isLoading && <CustomLoader />}
      {showForm && (
        <AddLectureForm
          chapterId={chapterId}
          refetchAllLectureByChapter={refetchAllLectureByChapter}
          showAddLecturePopup={setShowForm}
          lectureAllData={lectureAllData}
          setLectureAllData={setLectureAllData}
        />
      )}
      {/* <button
        onClick={() => {
          setShowForm(true);
          handleAddLecture();
        }}
        className="lg:absolute bottom-3 right-5 lg:!mx-0 flex items-center justify-center  rounded-full bg-orange-500 text-sm text-center font-normal p-5 w-[150px] h-[20px] text-white shadow-red-500 shadow-md mt-5"
      >
        Add Lectures
      </button> */}
      <div className="  flex items-center justify-between px-5  mt-5 ">
        <TbArrowNarrowLeft
          className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer  block lg:hidden `}
          onClick={() => setActiveStep(2)}
        />
        <CustomButton
          lable={`Create Lecture`}
          className={`bg-purpleColor text-white  border-none  lg:absolute bottom-0 right-5 mx-0`}
          onClick={() => {
            setShowForm(true);
            handleAddLecture();
          }}
        />
      </div>
      {data?.chapters?.lectures?.length > 0 ? (
        <div className="flex flex-wrap   items-start justify-start   w-full h-[90vh]   lg:h-[85vh] overflow-y-auto no-scrollbar pt-5 lg:pt-32 pb-5">
          {data?.chapters?.lectures?.map((lecture, index) => (
            <LectureCard
              key={index}
              title={lecture.lectureName}
              description={lecture.lectureDescription}
              setActiveStep={setActiveStep}
              handleClickLectureCard={() => handleClickLectureCard(lecture)}
              courseId={courseId}
              sectionId={currentSectionId}
              chapterId={chapterId}
              lectureAllData={lecture}
              setShowForm={setShowForm}
              refetchAllLectureByChapter={refetchAllLectureByChapter}
              currentLectureUid={currentLectureUid}
              currentLectureAllData={currentLectureAllData}
            />
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[50%] lg:w-[30%]" />
            </div>
            <p className="text-lg text-gray-700   ">
              You have not created any lecture yet
            </p>
          </div>
        )
      )}
      {/* <button
        disabled
        onClick={() => setActiveStep(4)}
        className="absolute bottom-10 flex items-center justify-center right-10 rounded-full bg-orange-500 text-sm text-center font-normal p-5 w-[150px] h-[20px] text-white shadow-red-500 shadow-md "
      >
        Next
      </button> */}
    </div>
  );
};

export default ManageLecture;
 
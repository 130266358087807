import React, { useContext } from "react";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CloudStorageCard from "../CloudStorageCard/CloudStorageCard";
import {
  useGetAllChaptersBySectionIdQuery,
  useGetAllChaptersQuery,
} from "../../../../redux/ReduxToolkit";
import CustomLoader from "../../../Loading/Loading";

import no_data_image from "../../../../assets/cloud_no_data.png";
import { AuthContext } from "../../../../Providers/AuthProvider";

function CloudChapter({
  setShowChapter,
  currentClickSectionChapterData,
  setShowLecture,
  setCurrentClickChapterVideosData,
  currentSectionId,
  setCurrentChapterId,
}) {
  const { accessToken } = useContext(AuthContext);

  const {
    data: allChapters,
    isLoading,
    refetch: reFetchManageChapter,
  } = useGetAllChaptersBySectionIdQuery({
    id: currentSectionId,
    token: accessToken,
  });

  const handleSectionFolderClick = (chapter, index) => {
    setCurrentChapterId(chapter?.chapterUid);
    setShowLecture(true);
  };

  return (
    <div className="flex ">
      <TbArrowNarrowLeft
        className={`border text-3xl rounded-lg border-gray-300 hover:bg-gray-100 px-1 cursor-pointer absolute top-3  lg:left-2 right-5`}
        onClick={() => setShowChapter(false)}
      />

      {isLoading && (
        <div className=" flex justify-center items-center w-full h-[85vh]">
          <CustomLoader />
        </div>
      )}

      {!isLoading &&
        (allChapters?.sections?.chapters?.length > 0 ? (
          <div className="mt-5 flex flex-row flex-wrap">
            {allChapters?.sections?.chapters?.map((chapter, index) => (
              <CloudStorageCard
                key={index}
                title={chapter?.chapterName}
                onDoubleClick={() => handleSectionFolderClick(chapter, index)}
              />
            ))}
          </div>
        ) : (
          <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[20%]" />
            </div>
            <p className="text-xl text-gray-700   ">
              You have not created any Chapter yet
            </p>
          </div>
        ))}
    </div>
  );
}

export default CloudChapter;
